import React, { memo } from 'react';

import { CaretDown, LockSimple, PencilSimple } from 'phosphor-react';

import { Container, Spinner } from './styles';
import { GroupRowCellProps } from './types';

export const GroupRowCell: React.FC<GroupRowCellProps> = memo(
  ({ row, isLoading, isMarketShareLoading }) => {
    const isGroup = !!row?.node?.group;
    let isExpanded = row.node.expanded;

    const isMarketShare =
      row.node?.id?.includes('y_type-marketshare') ||
      row?.node?.parent?.id?.includes('y_type-marketshare');

    let id = row.node.id;

    if ((!!Number(id) || id === '0') && row.node.data) {
      id = '';

      if (row.node?.data?.type) {
        id = `type-${row.node?.data?.type}-`;
      }

      id += `variable-${row.node?.data?.y_label}`;
    }

    const dataTestid = id
      ?.replaceAll(' ', '-')
      .toLowerCase()
      .replace('row-group-', '');

    const onExpandedChanged = (params: any) => {
      const button = document.getElementById(params.node.id ?? '');

      if (button) {
        button.setAttribute(
          'style',
          `transform: ${
            params.node.expanded ? 'rotate(180deg)' : 'rotate(0deg)'
          }`,
        );
      }
    };

    row.node.addEventListener('expandedChanged', onExpandedChanged);

    if (
      [
        'row-group-y_type-marketshare',
        'row-group-y_type-marketsize',
        'row-group-y_type-sellout',
        'row-group-y_type-others',
        'row-group-y_type-outros',
      ].includes(row.node.id ?? '')
    ) {
      return <div />;
    }

    return (
      <Container
        isMarketShare={!!isMarketShare}
        style={{ paddingLeft: `${(row.node.level * 12) / 16}rem` }}
      >
        <div>
          {isGroup || isMarketShare ? (
            <LockSimple
              data-testid={`lock-icon-${dataTestid}`}
              className="group-row-icon"
            />
          ) : (
            <PencilSimple
              data-testid={`pencil-icon-${dataTestid}`}
              className="group-row-icon"
            />
          )}

          <p data-testid={`cell-row-${dataTestid}-column-group`}>
            {(row as any).value}
            {isMarketShare && row.value === 'marketshare' ? ' (%)' : ''}
          </p>

          {(isLoading || (isMarketShare && isMarketShareLoading)) && (
            <Spinner data-testid={`loading-${dataTestid}`} />
          )}
        </div>

        {isGroup && (
          <button
            id={row.node.id}
            type="button"
            onClick={() => {
              isExpanded = !row.node.expanded;
              row.node.setExpanded(isExpanded);
            }}
            data-testid={`expand-button-${dataTestid}`}
            style={{
              transform: row.node.expanded ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
            aria-label="expand group button"
          >
            <CaretDown />
          </button>
        )}
      </Container>
    );
  },
  (prevProps, nextProps) =>
    prevProps.row.node.group === nextProps.row.node.group &&
    prevProps.row.node.expanded === nextProps.row.node.expanded &&
    prevProps.row.node.id === nextProps.row.node.id &&
    prevProps.row.node.level === nextProps.row.node.level &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.isMarketShareLoading === nextProps.isMarketShareLoading,
);
