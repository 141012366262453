import React, { useContext, useState } from 'react';

import { Trash } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { Modal } from 'src/components/Modal';
import { ErrorObject } from 'src/components/Modal/Failed';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Status } from 'src/components/Status';
import api from 'src/feature-store/service/api';
import { queryClient } from 'src/service/queryClient';
import { FeatureStoreSidebarContext } from 'src/feature-store/Contexts/NavigationContext';

import { MyGroupsProps } from '../../types';
import { ContainerModal } from './styles';

interface DeleteGroupModalProps {
  group: {
    id: string;
    name: string;
  };
  visible: boolean;
  categoryType: 'user' | 'highlights' | 'data' | 'model';
  setVisible: (value: boolean) => void;
  setError: (value: ErrorObject) => void;
  setErrorVisible: (value: boolean) => void;
}

type GroupsPage = {
  pages: MyGroupsProps[];
};

export const DeleteGroupModal: React.FC<DeleteGroupModalProps> = ({
  group,
  visible,
  categoryType,
  setVisible,
  setError,
  setErrorVisible,
}) => {
  const [inputGroupName, setInputGroupName] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [confirmationDisabled, setConfirmationDisabled] = useState(true);
  const [errorGroupName, setErrorGroupName] = useState<string | undefined>();

  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const { setterIdUserFavoriteViewingHome } = useContext(
    FeatureStoreSidebarContext,
  );

  function checkInputValue(value: string) {
    while (value === group.name.slice(0, value.length)) {
      setErrorGroupName(undefined);

      setConfirmationDisabled(value !== group.name);

      return;
    }

    setErrorGroupName(translate('deleteGroupModalMessageError'));
    setConfirmationDisabled(true);
  }

  async function handleDeleteGroup() {
    setInputGroupName(undefined);
    setErrorGroupName(undefined);
    setLoading(true);

    try {
      const { data } = await api.delete(`/groups/${group.id}`);

      if (data) {
        queryClient.refetchQueries(['favorites', categoryType]);
        queryClient.refetchQueries(['my groups page modal']);

        const favorites = queryClient.getQueryData<GroupsPage>([
          'favorites',
          categoryType,
        ]);

        const favoritesData = favorites?.pages[0].data;
        const idsFavorites = favoritesData?.flatMap((favorite) => favorite.id);
        const filterFavorites = idsFavorites?.filter(
          (favoriteId) => favoriteId !== group.id,
        );

        setVisible(false);

        setterIdUserFavoriteViewingHome('');
        queryClient.resetQueries(['list series on favorite', group.id, 1]);
        queryClient.resetQueries(['favorites', categoryType]);
        queryClient.resetQueries('user favorites');

        navigate(
          filterFavorites?.length
            ? `/feature-store/favorites/${filterFavorites[0]}`
            : `/feature-store/favorites/empty`,
        );
      }
    } catch (error) {
      setError({
        title: translate('requestFailed'),
        description: translate(
          'deleteGroupModalMessageRequestFailedDescription',
        ),
      });
      setErrorVisible(true);
      setVisible(false);
    }

    setLoading(false);
  }

  function handleCancel() {
    setInputGroupName(undefined);
    setErrorGroupName(undefined);
    setVisible(false);
  }

  return (
    <>
      <Modal visible={visible} setVisible={setVisible}>
        <ContainerModal>
          <Status
            type="deleteConfirmation"
            title={translate('deleteGroupModalTitle')}
            description={translate('deleteGroupModalDescription').replace(
              'XXX',
              group?.name,
            )}
            isModal
          />

          <Input
            placeholder={group?.name}
            data-testid="input-delete-group"
            style={{ marginTop: '24px', width: '100%' }}
            value={inputGroupName}
            onChange={(event) => {
              setInputGroupName(event.target.value);
              checkInputValue(event.target.value);
            }}
            error={errorGroupName}
          />
        </ContainerModal>
        <ModalFooter>
          <Button
            buttonType="naked"
            onClick={handleCancel}
            data-testid="button-delete-cancel"
          >
            {translate('cancel')}
          </Button>
          <Button
            buttonType="primary"
            icon={<Trash size="1.125rem" />}
            onClick={handleDeleteGroup}
            loading={loading}
            data-testid="button-delete-confirm"
            disabled={confirmationDisabled || loading}
          >
            {translate('delete')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
