import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AgGridReact } from 'ag-grid-react';
import ms from 'ms';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridTable } from 'src/components/AgGridTable';
import apiWorkspace from 'src/models/service/apiWorkspace';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  ExcelExportParams,
  ExcelStyle,
  GetRowIdParams,
  IAggFuncParams,
  ICellRendererParams,
  IRowNode,
  RowClassRules,
  RowHeightParams,
  ValueFormatterParams,
} from 'ag-grid-enterprise';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Status } from 'src/components/Status';
import { WorkspaceProjectionsForecastType } from 'src/models/redux/reducers/WorkspaceProjectionsOptions';
import {
  Step,
  StepStatus,
  changeEditionModeEnabled,
  changeIsDiscardingFirstStep,
  changeLastLogTime,
  changeReviewModeEnabled,
  changeWorkspaceOverviewForecast,
  changeWorkspaceOverviewOriginalPeriod,
  changeWorkspaceOverviewQuarterlyPeriod,
  changeWorkspaceOverviewStep,
  changeWorkspaceOverviewYearlyPeriod,
} from 'src/models/redux/reducers/WorkspaceOverviewOptions';
import { addMonths, subMonths } from 'date-fns';
import { AxiosError } from 'axios';
import { Button } from 'src/components/Button';
import {
  ArrowsClockwise,
  CheckCircle,
  DownloadSimple,
  FloppyDisk,
  LockSimple,
  PaperPlaneTilt,
  PencilSimple,
  SignOut,
  Trash,
  Warning,
  X,
} from 'phosphor-react';
import { Tooltip } from 'react-tooltip';
import { sleep } from 'src/utils/sleep';
import { updateCanSyncAdjust } from 'src/models/redux/reducers/Workspace';
import { SelectedFilters } from 'src/components/SelectedFilters';
import { SelectedFilterOptions } from 'src/components/SelectedFilters/types';
import { transformUppercaseFirstLetter } from 'src/utils/strings/transformUppercaseFirstLetter';
import { Card } from 'src/components/Card';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import light from 'src/styles/themes/light';

import { GroupRowCell } from '../TableRenderer/GroupRowCell';
import { ValueCell } from '../TableRenderer/ValueCell';
import { SessionExpiredModal } from '../Modals/SessionExpired';
import {
  ButtonUpdate,
  Container,
  DiscardingAdjustmentsContainer,
  DownloadButton,
  HeaderRightContent,
  SelectedFiltersExportContainer,
  SubmitApprovalContainer,
  TableButtonsContainer,
  TableContainer,
  TableError,
  TableStatus,
  Tag,
} from './styles';
import {
  Columns,
  DateIds,
  EditedValue,
  EditedValuesByYs,
  Frequency,
  FrequencyIntervalInMonths,
  MarketShareAggValues,
  MarketShareRow,
  RowData,
  SerieData,
  StartEndYForecastDates,
  UpdateStepStatusBody,
  YsDateIds,
  YsResponse,
} from './types';
import { aggFuncs } from './utils/aggFuncs';
import {
  dateFormatter,
  numberFormatter,
  variableTypeFormatter,
} from './utils/valueFormatter';
import { getSeriesQueryKey } from './utils/queryKeys';
import { useQueryStepLog } from '../../../hooks/useQueryStepLog';
import { useQueryReleaseData } from '../../../hooks/useQueryReleaseData';
import { NoPermissionToEditModal } from '../../../components/NoPermissionToEdit';
import { FlowMessage } from '../FlowMessage';
import { getSelectedFrequencyAndPeriod } from '../../utils/getSelectedFrequencyAndPeriod';
import { RequestApproval } from '../RequestApproval';
import { ActionConfirmationModal } from '../Modals/ActionConfirmation';
import { LogRecord } from '../../../hooks/useQueryStepLog/types';
import { ApproveOrDisapproveModal } from '../Modals/ApproveOrDisapprove';
import { UnsavedYsModal } from '../Modals/UnsavedYs';

const variablesTypeRowId = [
  'row-group-y_type-marketshare',
  'row-group-y_type-marketsize',
  'row-group-y_type-sellout',
  'row-group-y_type-others',
  'row-group-y_type-outros',
];

const frequencyIntervalInMonths: FrequencyIntervalInMonths = {
  daily: {
    historical: 3,
    forecast: 1,
  },
  weekly: {
    historical: 13,
    forecast: 1,
  },
  fortnightly: {
    historical: 12,
    forecast: 2,
  },
  monthly: {
    historical: 12,
    forecast: 12,
  },
  bimonthly: {
    historical: 24,
    forecast: 12,
  },
  quarterly: {
    historical: 12,
    forecast: 6,
  },
  'half-year': {
    historical: 24,
    forecast: 24,
  },
  yearly: {
    historical: 48,
    forecast: 24,
  },
  annual: {
    historical: 48,
    forecast: 24,
  },
};

export const Results: React.FC = memo(() => {
  const [tableRendered, setTableRendered] = useState(false);

  const [rowData, setRowData] = useState<RowData[]>([]);
  const [columns, setColumns] = useState<Columns>([]);

  const [ysDateIds, setYsDateIds] = useState<YsDateIds>({});
  const [marketShareRows, setMarketShareRows] = useState<MarketShareRow[]>([]);
  const [marketShareAggValues, setMarketShareAggValues] = useState<
    MarketShareAggValues[]
  >([]);

  const [isLoadingSeriesData, setIsLoadingSeriesData] = useState(false);
  const [isLoadingMarketShare, setIsLoadingMarketShare] = useState(false);

  const [startEndYForecastDates, setStartEndYForecastDates] =
    useState<StartEndYForecastDates>({});

  const [buttonUpdateStatus, setButtonUpdateStatus] = useState<
    'opened' | 'closed' | null
  >(null);

  const [loadingEnableEdition, setLoadingEnableEdition] = useState(false);
  const [showUserIsEditingModal, setShowUserIsEditingModal] = useState('');
  const [showWorkspaceIsPublishing, setShowWorkspaceIsPublishing] =
    useState(false);
  const [editionExpired, setEditionExpired] = useState(false);

  const [showUpdateStatusError, setShowUpdateStatusError] = useState(false);

  const [editedValues, setEditedValues] = useState<EditedValue[]>([]);
  const [invalidValues, setInvalidValues] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [unsavedYs, setUnsavedYs] = useState<string[]>([]);

  const [isDiscardingAdjustments, setIsDiscardingAdjustments] = useState(false);
  const [
    showDiscardAdjustmentsConfirmation,
    setShowDiscardAdjustmentsConfirmation,
  ] = useState(false);
  const [showRequestApproval, setShowRequestApproval] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDisapproveModal, setShowDisapproveModal] = useState(false);

  const tableRef = useRef<AgGridReact>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  const {
    auth: { user },
    workspace: {
      id: workspaceId,
      name: workspaceName,
      releaseSelected,
      frequency: workspaceFrequency,
      userRole,
      releasePreview,
    },
    workspaceOverviewOptions: {
      frequency,
      transformation,
      forecast: forecastType,
      period,
      editionModeEnabled,
      inflation,
      step,
      lastLogTimeByStep,
      reviewModeEnabled,
      isDiscadingFirstStep,
    },
  } = useSelector((state: RootState) => state);

  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const { data: releaseData, isLoading: releaseIsLoading } =
    useQueryReleaseData(workspaceId, releaseSelected?.id);

  const { data: updatedStepLogData, isLoading: updatedStepLogIsLoading } =
    useQueryStepLog(
      workspaceId,
      releaseSelected?.id ?? null,
      step?.number ?? null,
      'updated_step',
      0,
      1,
    );

  const { data: adjustedStepLogData } = useQueryStepLog(
    workspaceId,
    releaseSelected?.id ?? null,
    step?.number ?? null,
    'adjusted',
    0,
    1,
  );

  const { data: discardedAdjustedStepLogData } = useQueryStepLog(
    workspaceId,
    releaseSelected?.id ?? null,
    step?.number ?? null,
    'discarded_adjusted',
    0,
    1,
  );

  const { data: removedStepLogData } = useQueryStepLog(
    workspaceId,
    releaseSelected?.id ?? null,
    step?.number ?? null,
    'removed_step',
    0,
    1,
  );

  const {
    data: ysInfoData,
    isLoading: ysInfoIsLoading,
    isFetching: ysInfoIsFetching,
    isError: ysInfoIsError,
  } = useQuery(
    ['workspace', 'ys info', workspaceId, releaseSelected?.id],
    async () => {
      const { data } = await apiWorkspace.get<YsResponse>(
        `workspaces/${workspaceId}/releases/${releaseSelected?.id}/ys`,
      );

      return data;
    },
    {
      enabled: !!workspaceId && !!releaseSelected?.id,
      staleTime: ms('1 day'),
    },
  );

  const selectedFilters = useMemo(() => {
    const selectedFilterInflation =
      user.language === 'pt-br'
        ? `${translate('value')} ${inflation}`
        : `${transformUppercaseFirstLetter(inflation)} ${translate(
            'value',
          ).toLowerCase()}`;

    const filters: SelectedFilterOptions[] = [
      {
        type: 'frequency',
        selected: getSelectedFrequencyAndPeriod(
          translate(frequency),
          period[frequency === 'monthly' ? 'original' : frequency],
          user.language,
        ),
      },
      { type: 'transformation', selected: translate(transformation) },
    ];

    const hasForecastLabel =
      workspaceFrequency === 'monthly' &&
      !releaseData?.data.approval_flow?.enable &&
      releaseSelected?.id !== releasePreview;

    hasForecastLabel &&
      filters.push({
        type: 'other',
        id: 'forecast',
        icon: <PencilSimple />,
        selected: translate(
          forecastType === 'adjusted'
            ? 'workspaceProjectionsMostRecent'
            : forecastType,
        ),
      });

    const hasInflationLabel = releaseSelected?.data.ys.some(
      (y) => y.is_inflated,
    );

    hasInflationLabel &&
      filters.push({
        type: 'inflation',
        selected: selectedFilterInflation,
      });

    return filters;
  }, [
    forecastType,
    frequency,
    inflation,
    period,
    releaseData?.data.approval_flow?.enable,
    releaseSelected?.id,
    releasePreview,
    transformation,
    translate,
    user.language,
    workspaceFrequency,
    releaseSelected?.data.ys,
  ]);

  const rowClassRules = useMemo<RowClassRules>(
    () => ({
      'marketshare-cell': (params) =>
        !!params.node.id?.startsWith('marketshare-') ||
        !!params.node.id?.startsWith('row-group-y_type-marketshare-'),
    }),
    [],
  );

  const excelStyles: ExcelStyle[] = useMemo(() => {
    if (ysInfoData) {
      const excelBorders = {
        borderBottom: {
          color: light.colors.gray3,
        },
        borderTop: {
          color: light.colors.gray3,
        },
        borderLeft: {
          color: light.colors.gray3,
        },
        borderRight: {
          color: light.colors.gray3,
        },
      };

      const defaultOptions: ExcelStyle[] = [
        {
          id: 'header',
          alignment: {
            horizontal: 'Center',
          },
          borders: excelBorders,
        },
        {
          id: 'forecast-values',
          font: {
            color: light.colors.secondary,
          },
          interior: {
            color: '#FEF5F9',
            pattern: 'Solid',
          },
          borders: excelBorders,
        },
        {
          id: 'historical-values',
          borders: excelBorders,
        },
      ];

      return defaultOptions;
    }

    return [];
  }, [ysInfoData]);

  const defaultColDef: ColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 90,
      sortable: false,
      unSortIcon: true,
      resizable: false,
      suppressMovable: true,
    }),
    [],
  );

  const autoGroupColumnDef: ColDef = useMemo(
    () => ({
      headerName: translate('workspaceOverviewResultsGroup'),
      field: 'y_label',
      pinned: 'left',
      flex: 1,
      minWidth: 115,
      width: 250,
      autoHeight: true,
      suppressMovable: true,
      lockPinned: true,
      suppressHeaderMenuButton: true,
      enableRowGroup: true,
      resizable: true,
      cellRendererParams: {
        suppressCount: true,
      },
      cellRenderer: (row: ICellRendererParams) => (
        <GroupRowCell
          row={row}
          isLoading={isLoadingSeriesData}
          isMarketShareLoading={isLoadingMarketShare}
        />
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingSeriesData, isLoadingMarketShare],
  );

  const getRowId = useCallback(
    (params: GetRowIdParams) => params.data.row_id,
    [],
  );

  const getRowHeight = useCallback((params: RowHeightParams) => {
    if (variablesTypeRowId.includes(params.node.id ?? '')) return 1;
    return 44;
  }, []);

  const getSerieData = useCallback(
    async (
      yLabel: string,
      aggregationQuery: string[] = [],
      originalOrAdjusted: WorkspaceProjectionsForecastType,
      isInflated: boolean,
      yType: string | undefined = undefined,
      stepNumberAux?: number,
    ) => {
      const stepNumber = stepNumberAux ?? step?.number ?? 1;

      const marketShareEnabled = ysInfoData?.market_share?.enable;

      const queryKey = getSeriesQueryKey(
        workspaceId ?? '',
        releaseSelected?.id ?? '',
        aggregationQuery.toString().replaceAll(',', ' ') || yLabel,
        frequency,
        transformation,
        inflation,
        originalOrAdjusted,
        stepNumber,
        marketShareEnabled ? yType : undefined,
      );

      let queryData: SerieData;

      const response = queryClient.getQueryData<SerieData>(queryKey);
      const queryState = queryClient.getQueryState(queryKey);

      let serieInflation: 'inflate' | 'original' = 'inflate';

      if (
        inflation === 'real' ||
        (!aggregationQuery.length && isInflated === false)
      ) {
        serieInflation = 'original';
      }

      if (response && !queryState?.isInvalidated && !queryState?.isFetching) {
        queryData = response;
      } else {
        let route = '';

        if (aggregationQuery.length || yType) {
          let hierarchy = '';
          aggregationQuery.forEach((aggregation) => {
            hierarchy = hierarchy.concat(
              `hierarchy=${encodeURI(aggregation)}&`,
            );
          });

          if (yType === 'marketshare') {
            route = `/workspaces/${workspaceId}/releases/${releaseSelected?.id}/series/calculated?calc=marketshare&${hierarchy}frequency=${frequency}&transformation=${transformation}&inflation=${serieInflation}&data_type=${originalOrAdjusted}&step=${stepNumber}`;
          } else {
            const typeAux =
              marketShareEnabled && yType
                ? encodeURI(`filter=y_type=${yType}&`)
                : '';
            route = `/workspaces/${workspaceId}/releases/${releaseSelected?.id}/series/calculated?calc=sum&${hierarchy}${typeAux}frequency=${frequency}&transformation=${transformation}&inflation=${serieInflation}&data_type=${originalOrAdjusted}&step=${stepNumber}`;
          }
        } else {
          route = `/workspaces/${workspaceId}/releases/${releaseSelected?.id}/series/raw/${yLabel}?frequency=${frequency}&transformation=${transformation}&inflation=${serieInflation}&data_type=${originalOrAdjusted}&step=${stepNumber}`;
        }

        try {
          queryData = await queryClient.fetchQuery<SerieData>(
            queryKey,
            async () => {
              const { data } = await apiWorkspace.get<SerieData>(route);

              return {
                serie: yLabel,
                historical: data.historical,
                forecast: data?.forecast,
              };
            },
            {
              staleTime: ms('1 day'),
              cacheTime: ms('1 day'),
            },
          );
        } catch {
          queryData = {
            serie: yLabel,
            historical: {
              dates: [],
              values: [],
              ids: [],
            },
          };
        }
      }

      return queryData;
    },
    [
      frequency,
      inflation,
      releaseSelected?.id,
      transformation,
      workspaceId,
      step?.number,
      ysInfoData?.market_share?.enable,
    ],
  );

  const getYsPromises = useCallback(
    (startIndex: number, endIndex: number) => {
      const originalYsPromises: Promise<SerieData>[] = [];
      const adjustedYsPromises: Promise<SerieData>[] = [];

      if (ysInfoData) {
        for (let j = startIndex; j < endIndex; j++) {
          if (ysInfoData.ys.length > j) {
            const label = ysInfoData.ys[j].y_label;
            const isInflated = ysInfoData.ys[j].is_inflated;
            originalYsPromises.push(
              getSerieData(label, [], 'original', isInflated),
            );
            adjustedYsPromises.push(
              getSerieData(label, [], 'adjusted', isInflated),
            );
          } else {
            break;
          }
        }
      }

      return { originalYsPromises, adjustedYsPromises };
    },
    [getSerieData, ysInfoData],
  );

  const getAggPromises = useCallback(
    (startIndex: number, endIndex: number) => {
      const aggsPromises: Promise<SerieData>[] = [];

      if (ysInfoData && ysInfoData.aggregation?.enable) {
        for (let j = startIndex; j < endIndex; j++) {
          if (ysInfoData.ys.length > j) {
            const label = ysInfoData.ys[j].y_label;
            const type = ysInfoData.ys[j].type;
            const isInflated = ysInfoData.ys[j].is_inflated;

            const hierarchy = ysInfoData.ys[j].hierarchy.map(
              (aux) => aux.value,
            );
            for (let i = 1; i <= hierarchy.length; i++) {
              aggsPromises.push(
                getSerieData(
                  label,
                  hierarchy.slice(0, i),
                  'original',
                  isInflated,
                  type,
                ),
                getSerieData(
                  label,
                  hierarchy.slice(0, i),
                  'adjusted',
                  isInflated,
                  type,
                ),
              );
            }
          } else {
            break;
          }
        }
      }

      return { aggsPromises };
    },
    [getSerieData, ysInfoData],
  );

  const defaultColumnCellClass = useCallback(
    (params: CellClassParams) => {
      if (
        params.colDef.type?.includes('forecastColumn') &&
        !params.data?.y_label
      ) {
        return 'forecast-values';
      }

      const rowId = params.data?.row_id;
      const date = new Date(params.colDef.field ?? '');

      const { startDate } = startEndYForecastDates[rowId] ?? {};

      return !!startDate && date >= new Date(startDate)
        ? 'forecast-values'
        : 'historical-values';
    },
    [startEndYForecastDates],
  );

  const isForecast = useCallback(
    (yLabel: string, date: Date) => {
      const { startDate, endDate } = startEndYForecastDates[yLabel] ?? {};

      return (
        !!startDate &&
        !!endDate &&
        date >= new Date(startDate) &&
        date <= new Date(endDate)
      );
    },
    [startEndYForecastDates],
  );

  const editable = useCallback(
    (yLabel: string, date: Date, id: string) =>
      editionModeEnabled &&
      !id.startsWith('row-group-y_type-marketshare') &&
      isForecast(yLabel, date),

    [isForecast, editionModeEnabled],
  );

  const getHierarchies = useCallback((rowNode: IRowNode | null): string[] => {
    const hierarchies: string[] = [];
    let row = rowNode;

    while (row && row.key) {
      hierarchies.unshift(row.key);
      row = row.parent;
    }

    return hierarchies;
  }, []);

  const sumAgg = useCallback((params: IAggFuncParams) => {
    let sum = 0;

    if (params.values.every((value) => Number.isNaN(Number(value)))) {
      return undefined;
    }

    params.values.forEach((value) => {
      sum += value || 0;
    });

    return sum;
  }, []);

  const marketShareAgg = useCallback(
    (params: IAggFuncParams) => {
      const hierarchy = getHierarchies(params.rowNode).slice(1);

      if (hierarchy.length) {
        const marketShareValues = marketShareAggValues.find(
          (information) =>
            information.hierarchy.toString() === hierarchy.toString(),
        );

        if (marketShareValues) {
          const dateColumn = params.colDef.field ?? '';

          const allDates = [
            ...(marketShareValues.historical?.dates ?? []),
            ...(marketShareValues.forecast?.dates ?? []),
          ];
          const allValues = [
            ...(marketShareValues.historical?.values ?? []),
            ...(marketShareValues.forecast?.values ?? []),
          ];

          const valueIndex = allDates.findIndex(
            (date) => date.replace(':00Z', '') === dateColumn,
          );

          return valueIndex === -1 ? null : allValues[valueIndex];
        }
      }

      return null;
    },
    [marketShareAggValues, getHierarchies],
  );

  const aggFunc = useCallback(
    (params: IAggFuncParams) => {
      const dateColumn = params.colDef.field ?? '';
      const dateFormatted = new Date(dateColumn);

      if (!ysInfoData?.aggregation?.enable) {
        return null;
      }

      const freqPeriod =
        period[frequency === 'monthly' ? 'original' : frequency];

      if (
        !freqPeriod[0] ||
        !freqPeriod[1] ||
        dateFormatted < freqPeriod[0] ||
        dateFormatted > freqPeriod[1]
      ) {
        return null;
      }

      if (params.rowNode.id?.startsWith(`row-group-y_type-marketshare`)) {
        return marketShareAgg(params);
      }

      if (transformation === 'variation') {
        return null;
      }

      return sumAgg(params);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      ysInfoData?.aggregation?.enable,
      transformation,
      sumAgg,
      marketShareAgg,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      period[frequency === 'monthly' ? 'original' : frequency],
    ],
  );

  const getMarketShareData = useCallback(
    async (signal: AbortSignal, stepNumberAux?: number) => {
      if (marketShareRows.length) {
        const stepNumber = stepNumberAux ?? step?.number;

        setIsLoadingMarketShare(true);

        const originalPromises: Promise<SerieData>[] = [];
        const adjustedPromises: Promise<SerieData>[] = [];
        const originalAggPromises: Promise<SerieData>[] = [];
        const adjustedAggPromises: Promise<SerieData>[] = [];

        const aggValues: MarketShareAggValues[] = [];

        for (let i = 0; i < marketShareRows.length; i++) {
          const { hierarchy, isAggregation, rowId } = marketShareRows[i];

          const queryKeyAdjusted = getSeriesQueryKey(
            workspaceId ?? '',
            releaseSelected?.id ?? '',
            hierarchy.toString().replaceAll(',', ' '),
            frequency,
            transformation,
            inflation,
            'adjusted',
            stepNumber,
            'marketshare',
          );

          const queryKeyOriginal = getSeriesQueryKey(
            workspaceId ?? '',
            releaseSelected?.id ?? '',
            hierarchy.toString().replaceAll(',', ' '),
            frequency,
            transformation,
            inflation,
            'original',
            stepNumber,
            'marketshare',
          );

          await queryClient.invalidateQueries(queryKeyAdjusted);
          await queryClient.invalidateQueries(queryKeyOriginal);

          if (!isAggregation) {
            originalPromises.push(
              getSerieData(
                rowId ?? '',
                hierarchy,
                'original',
                false,
                'marketshare',
                stepNumber,
              ),
            );

            adjustedPromises.push(
              getSerieData(
                rowId ?? '',
                hierarchy,
                'adjusted',
                false,
                'marketshare',
                stepNumber,
              ),
            );
          } else {
            adjustedAggPromises.push(
              getSerieData(
                rowId ?? '',
                hierarchy,
                'adjusted',
                false,
                'marketshare',
                stepNumber,
              ),
            );
            originalAggPromises.push(
              getSerieData(
                rowId ?? '',
                hierarchy,
                'original',
                false,
                'marketshare',
                stepNumber,
              ),
            );

            aggValues.push({ hierarchy });
          }
        }

        let index = 0;

        while (index < originalPromises.length && !signal.aborted) {
          try {
            const originalData = await Promise.all(
              originalPromises.slice(index, index + 100),
            );
            const adjustedData = await Promise.all(
              adjustedPromises.slice(index, index + 100),
            );

            if (!signal.aborted) {
              const data =
                forecastType === 'adjusted' ? adjustedData : originalData;

              for (let i = 0; i < data.length; i++) {
                const row: RowData = {};

                data[i].historical.dates.forEach((date, j) => {
                  const updatedDate = date.replace(':00Z', '');

                  row[updatedDate] = data[i].historical.values[j];
                });

                data[i].forecast?.dates.forEach((date, j) => {
                  const updatedDate = date.replace(':00Z', '');

                  row[updatedDate] = data[i].forecast?.values[j] ?? 0;
                });

                const rowNode = tableRef.current?.api!.getRowNode(
                  data[i].serie,
                );

                const forecastDates = data[i].forecast?.dates;

                let startDate: null | string = null;
                let endDate: null | string = null;

                if (forecastDates?.length) {
                  startDate = forecastDates[0].replace(':00Z', '');
                  endDate = forecastDates[forecastDates?.length - 1].replace(
                    ':00Z',
                    '',
                  );
                }

                setStartEndYForecastDates((prev) => ({
                  ...prev,
                  [data[i].serie]: {
                    startDate,
                    endDate,
                  },
                }));

                if (rowNode) {
                  rowNode.updateData({
                    ...rowNode.data,
                    ...row,
                  });
                }
              }
            }
            // eslint-disable-next-line no-empty
          } catch {}

          index += 100;
        }

        index = 0;

        while (index < originalAggPromises.length && !signal.aborted) {
          try {
            const originalData = await Promise.all(
              originalAggPromises.slice(index, index + 100),
            );
            const adjustedData = await Promise.all(
              adjustedAggPromises.slice(index, index + 100),
            );

            if (!signal.aborted) {
              const data =
                forecastType === 'adjusted' ? adjustedData : originalData;

              for (let i = 0; i < data.length; i++) {
                aggValues[index + i].historical = data[i].historical;
                aggValues[index + i].forecast = data[i].forecast;
              }
            }
            // eslint-disable-next-line no-empty
          } catch {}

          index += 100;
        }

        setMarketShareAggValues(aggValues);
        setIsLoadingMarketShare(false);
      }
    },
    [
      workspaceId,
      releaseSelected?.id,
      marketShareRows,
      frequency,
      forecastType,
      inflation,
      transformation,
      step?.number,
      getSerieData,
    ],
  );

  const getOriginalAdjustedValue = useCallback(
    (
      rowNode: IRowNode,
      dateColumn: string,
      originalOrAdjusted: 'original' | 'adjusted',
    ) => {
      let yLabel = '';
      let isAggregation = false;
      let aggregationKey: string[] = [];
      let aggregationKeyString = '';

      const isMarketShare =
        ysInfoData?.market_share?.enable &&
        (!!rowNode.id?.startsWith('marketshare-') ||
          !!rowNode.id?.startsWith('row-group-y_type-marketshare-'));

      if (rowNode.data?.y_label) {
        yLabel = rowNode.data?.y_label;

        if (isMarketShare) {
          aggregationKey = getHierarchies(rowNode.parent);

          aggregationKey.push(yLabel);
        }
      } else {
        yLabel = rowNode.key || '';
        isAggregation = true;
        aggregationKey = getHierarchies(rowNode);
      }

      const yType =
        aggregationKey[0] === 'marketshare'
          ? 'marketshare'
          : aggregationKey[0] === 'marketsize'
          ? 'marketsize'
          : aggregationKey[0] === 'sellout'
          ? 'sellout'
          : 'others';

      if (ysInfoData?.market_share?.enable) {
        aggregationKey = aggregationKey.slice(1);
      }

      aggregationKeyString = aggregationKey.toString().replaceAll(',', ' ');

      let originalValue: null | number = null;

      if (!aggregationKeyString && !yLabel) return 0;

      if (yLabel) {
        const isY = !isAggregation && !isMarketShare;

        const queryKey = getSeriesQueryKey(
          workspaceId ?? '',
          releaseSelected?.id ?? '',
          !isY ? aggregationKeyString : yLabel,
          frequency,
          transformation,
          inflation,
          originalOrAdjusted,
          step?.number ?? 1,
          isY || !ysInfoData?.market_share?.enable ? undefined : yType,
        );

        const response: SerieData | undefined =
          queryClient.getQueryData(queryKey);
        const queryState = queryClient.getQueryState(queryKey);

        if (
          response &&
          response.historical.dates &&
          response.forecast?.dates &&
          !queryState?.isInvalidated &&
          !queryState?.isFetching
        ) {
          if (isAggregation && transformation === 'variation') {
            const historicalIndex = response.historical?.dates.findIndex(
              (dateAux) => dateAux.replace(':00Z', '') === dateColumn,
            );

            if (historicalIndex !== -1) {
              originalValue = response.historical.values[historicalIndex];
            }
          }

          if (!originalValue) {
            const forecastIndex = response.forecast?.dates.findIndex(
              (dateAux) => dateAux.replace(':00Z', '') === dateColumn,
            );

            if (forecastIndex !== -1) {
              originalValue = response.forecast.values[forecastIndex];
            }
          }
        }
      }

      if (typeof originalValue === 'number') return originalValue;

      return 0;
    },
    [
      frequency,
      inflation,
      releaseSelected?.id,
      transformation,
      workspaceId,
      ysInfoData?.market_share?.enable,
      step?.number,
      getHierarchies,
    ],
  );

  const refetchLogs = async (stepNumber?: number) => {
    queryClient.refetchQueries({
      queryKey: ['workspace', 'logs'],
    });

    await queryClient.refetchQueries([
      'workspace',
      workspaceId,
      'releases',
      releaseSelected?.id,
      'logs',
      stepNumber ?? step?.number ?? 1,
    ]);
  };

  const invalidateChartDataAndAggregationAndMarketShareData = async (
    stepNumber?: number,
  ) => {
    queryClient.refetchQueries(['workspace', 'series data chart']);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    if (ysInfoData?.market_share?.enable) {
      await getMarketShareData(abortControllerRef.current.signal, stepNumber);
    }

    if (ysInfoData?.aggregation?.enable) {
      ysInfoData?.ys.forEach((y) => {
        const hierarchy = y.hierarchy.map((aux) => aux.value);

        for (let i = 1; i <= hierarchy.length; i++) {
          const queryKeyAdjustedNominal = getSeriesQueryKey(
            workspaceId ?? '',
            releaseSelected?.id ?? '',
            hierarchy.slice(0, i).toString().replaceAll(',', ' '),
            'original',
            'level',
            'nominal',
            'adjusted',
            stepNumber ?? step?.number ?? 1,
          );

          queryClient.invalidateQueries(queryKeyAdjustedNominal.slice(0, 7));

          const queryKeyAdjustedReal = getSeriesQueryKey(
            workspaceId ?? '',
            releaseSelected?.id ?? '',
            hierarchy.slice(0, i).toString().replaceAll(',', ' '),
            'original',
            'level',
            'real',
            'adjusted',
            stepNumber ?? step?.number ?? 1,
          );

          queryClient.invalidateQueries(queryKeyAdjustedReal.slice(0, 7));
        }
      });
    }
  };

  const handleUpdateData = async (
    stepNumber?: number,
    clickedOnButton = true,
  ) => {
    if (ysInfoData) {
      for (let i = 0; i < ysInfoData.ys.length; i++) {
        const queryKey = getSeriesQueryKey(
          workspaceId ?? '',
          releaseSelected?.id ?? '',
          ysInfoData.ys[i].y_label,
          frequency,
          transformation,
          ysInfoData.ys[i].is_inflated ? 'nominal' : 'real',
          'adjusted',
          stepNumber ?? step?.number ?? 1,
        );

        queryClient.removeQueries(queryKey.slice(0, 7));
      }
    }

    await invalidateChartDataAndAggregationAndMarketShareData(
      stepNumber ?? step?.number ?? 1,
    );

    const tableApi = tableRef?.current?.api;

    setYsDateIds({});
    setIsLoadingSeriesData(false);

    if (columns.length && tableApi) {
      const resetTableColumns = [...columns];
      resetTableColumns[0].children = [];
      resetTableColumns[1].children = [];

      tableApi!.setGridOption('columnDefs', resetTableColumns);
    }

    if (tableRendered) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();

      setIsLoadingSeriesData(true);
      getAllSeriesData(abortControllerRef.current.signal);
    }

    if (
      adjustedStepLogData?.records &&
      discardedAdjustedStepLogData?.records &&
      removedStepLogData?.records &&
      step?.number !== undefined
    ) {
      const joinArrayAdjustedAndDiscard = [
        ...adjustedStepLogData.records,
        ...discardedAdjustedStepLogData.records,
        ...removedStepLogData.records,
      ].sort(
        (a, b) =>
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
      );

      const adjustedLastLogTime = new Date(
        joinArrayAdjustedAndDiscard[0].timestamp,
      ).getTime();

      dispatch(
        changeLastLogTime({
          step: stepNumber ?? step?.number ?? 1,
          value: adjustedLastLogTime,
        }),
      );
    }

    if (clickedOnButton) {
      setButtonUpdateStatus('closed');
    }
  };

  const lockEdition = async () => {
    try {
      await apiWorkspace.patch(`/workspaces/${workspaceId}/edit`);

      return true;
    } catch (err) {
      const error = err as AxiosError;
      if (error.response?.status === 400) {
        if (
          error.response?.data?.detail?.detail?.startsWith(
            'Workspace already locked for editing by ',
          )
        ) {
          setShowUserIsEditingModal(
            error.response.data.detail.detail.replace(
              'Workspace already locked for editing by ',
              '',
            ),
          );
        } else if (
          error.response?.data?.detail?.detail ===
          'You cannot perform this action, Workspace is publishing a new version.'
        ) {
          setShowWorkspaceIsPublishing(true);
        }
      }

      return false;
    }
  };

  const unlockEdition = async () => {
    try {
      await apiWorkspace.delete(`/workspaces/${workspaceId}/edit`);
      // eslint-disable-next-line no-empty
    } catch {}
  };

  const updateStepStatus = async (
    status: StepStatus,
    message?: string,
  ): Promise<boolean> => {
    if (!step || step.status === status) return true;

    try {
      const updatedStep: Step = { ...step, status, selectedStatus: status };

      const body: UpdateStepStatusBody = { status };

      if (status === 'awaiting_approval' && message) {
        updatedStep.awaiting_approval_messages = [
          message,
          ...updatedStep.awaiting_approval_messages,
        ];

        body.awaiting_approval_messages = [message];
      }

      if (status === 'approved' && message) {
        updatedStep.approval_messages = [
          message,
          ...updatedStep.approval_messages,
        ];

        body.approval_messages = [message];
      }

      if (status === 'adjusting' && message) {
        updatedStep.disapproval_messages = [
          message,
          ...updatedStep.disapproval_messages,
        ];

        body.disapproval_messages = [message];
      }

      await apiWorkspace.put<Step>(
        `/workspaces/${workspaceId}/releases/${releaseSelected?.id}/steps/${step?.number}`,
        body,
      );

      dispatch(changeWorkspaceOverviewStep(updatedStep));

      if (status === 'awaiting_approval') {
        await unlockEdition();
      }

      refetchLogs();

      queryClient.refetchQueries({
        queryKey: ['workspace', workspaceId, 'releases', releaseSelected?.id],
        exact: true,
      });

      return true;
    } catch {
      setShowUpdateStatusError(true);

      return false;
    }
  };

  const handleEnableEdition = async () => {
    setLoadingEnableEdition(true);

    const success = await lockEdition();

    if (success) {
      if (
        step &&
        (step.number === 1 ||
          releaseData?.data?.steps?.[step.number - 1].status === 'baseline')
      ) {
        updateStepStatus('adjusting');
      }

      if (!editionModeEnabled) {
        dispatch(changeEditionModeEnabled(true));
      }
    }

    setEditionExpired(false);
    setLoadingEnableEdition(false);
  };

  const handleSaveEdition = async () => {
    try {
      await apiWorkspace.patch(`/workspaces/${workspaceId}/edit`);

      const editedValuesByYs: EditedValuesByYs = {};
      const labels: string[] = [];

      editedValues.forEach((edited) => {
        const label = edited.yLabel;

        const editedValuesY = editedValuesByYs[label];

        if (!editedValuesY) {
          labels.push(label);
          editedValuesByYs[label] = [];
        }

        editedValuesByYs[label].push({
          date: `${edited.date}:00Z`,
          value: edited.adjustedValue,
          id: ysDateIds[label][edited.date],
        });
      });

      let errorToSave = false;

      for (let i = 0; i < labels.length; i++) {
        const isInflated = ysInfoData?.ys.find(
          (y) => y.y_label === labels[i],
        )?.is_inflated;
        const yInflation = isInflated ? 'inflate' : 'original';

        try {
          await apiWorkspace.put(
            `/workspaces/${workspaceId}/releases/${
              releaseSelected?.id
            }/series/raw/${labels[i]}?inflation=${yInflation}&step=${
              step?.number ?? 1
            }`,
            { values: editedValuesByYs[labels[i]] },
          );

          const queryKey = getSeriesQueryKey(
            workspaceId ?? '',
            releaseSelected?.id ?? '',
            labels[i],
            'original',
            'level',
            isInflated ? 'nominal' : 'real',
            forecastType,
            step?.number ?? 1,
          );

          queryClient.invalidateQueries(queryKey.slice(0, 7));
        } catch {
          errorToSave = true;

          setUnsavedYs((prev) => [...prev, labels[i]]);
        }
      }

      if (step) {
        await queryClient.refetchQueries({
          queryKey: [
            'workspace',
            workspaceId,
            'releases',
            releaseSelected?.id,
            'logs',
            step.number,
          ],
        });

        queryClient.refetchQueries(['workspace', 'logs']);
      }

      invalidateChartDataAndAggregationAndMarketShareData();

      if (!errorToSave) {
        dispatch(changeEditionModeEnabled(false));
        dispatch(updateCanSyncAdjust(true));
        setEditedValues([]);

        await unlockEdition();
      }
    } catch (err) {
      const error = err as AxiosError;
      if (error.response?.status === 400) {
        if (
          error.response?.data?.detail?.detail?.startsWith(
            'Workspace already locked for editing by ',
          )
        ) {
          setShowUserIsEditingModal(
            error.response.data.detail.detail.replace(
              'Workspace already locked for editing by ',
              '',
            ),
          );
        } else if (
          error.response?.data?.detail?.detail ===
          'You cannot perform this action, Workspace is publishing a new version.'
        ) {
          setShowWorkspaceIsPublishing(true);
        }
      }
    }

    setIsSaving(false);
  };

  const handleEditedValue = async (
    yLabel: string,
    date: string,
    value: number,
    oldValue: number,
    rowId: string,
  ) => {
    if (yLabel) {
      const variableIndex = editedValues.findIndex(
        (y) => y.yLabel === yLabel && y.date === date,
      );

      const updatedEditedValues = [...editedValues];

      if (variableIndex === -1) {
        updatedEditedValues.push({
          yLabel,
          date,
          originalValue: oldValue,
          adjustedValue: value,
          rowId,
        });
      } else {
        updatedEditedValues[variableIndex].adjustedValue = value;
      }

      setEditedValues(updatedEditedValues);

      if (value <= 0 && oldValue > 0 && yLabel) {
        setInvalidValues(invalidValues + 1);
      }

      if (oldValue <= 0 && value > 0 && yLabel) {
        setInvalidValues(invalidValues - 1);
      }
    }
  };

  const handleExitEdition = async () => {
    editedValues.forEach((y) => {
      const rowNode = tableRef.current!.api.getRowNode(y.rowId)!;

      rowNode.setDataValue(y.date, y.originalValue);
    });

    setEditedValues([]);
    dispatch(changeEditionModeEnabled(false));

    await unlockEdition();

    setEditionExpired(false);
  };

  const handleDiscardAllAdjustments = async () => {
    if (!step) return;

    const success = await lockEdition();

    if (success) {
      try {
        await apiWorkspace.patch<Step>(
          `/workspaces/${workspaceId}/releases/${releaseSelected?.id}/steps/${step?.number}`,
        );

        dispatch(
          changeWorkspaceOverviewStep({
            ...step,
            approval_messages: [],
            awaiting_approval_messages: [],
            disapproval_messages: [],
          }),
        );

        queryClient.refetchQueries(['workspace', 'logs']);

        queryClient.refetchQueries({
          queryKey: ['workspace', workspaceId, 'releases', releaseSelected?.id],
          exact: true,
        });

        setIsDiscardingAdjustments(true);
        setTableRendered(false);

        // eslint-disable-next-line no-empty
      } catch {}

      await unlockEdition();
    }
  };

  const handleStartReview = () => {
    dispatch(changeReviewModeEnabled(true));
  };

  const handleExitReview = () => {
    dispatch(changeReviewModeEnabled(false));
  };

  const handleApproveReview = async (message: string) => {
    await updateStepStatus('approved', message);

    setShowApproveModal(false);
    dispatch(changeReviewModeEnabled(false));
  };

  const handleDisapproveReview = async (message: string) => {
    await updateStepStatus('adjusting', message);

    setShowDisapproveModal(false);
    dispatch(changeReviewModeEnabled(false));
  };

  const handleDownloadTable = () => {
    if (tableRef?.current) {
      const allColumns = tableRef.current?.api.getGridOption('columnDefs');
      const dateColumnsToExport: string[] = [];

      allColumns?.forEach((col) => {
        if (col.headerName) {
          const column = col as ColGroupDef;

          column?.children?.forEach(({ field }: ColDef, i) => {
            if (field) {
              const dateColumn = column.children[i] as ColDef;

              if (!dateColumn.hide) {
                dateColumnsToExport.push(dateColumn.field ?? '');
              }
            }
          });
        }
      });

      const config: ExcelExportParams = {
        sheetName: translate('workspaceOverviewResultsTitle'),
        fileName: `${workspaceName} - ${releaseSelected?.name}`,
        exportedRows: 'all',
        suppressRowOutline: true,
        allColumns: false,
        columnKeys: [
          'y_type',
          ...(ysInfoData?.hierarchies ?? []),
          'ag-Grid-AutoColumn',
          ...dateColumnsToExport,
        ],
        shouldRowBeSkipped(params) {
          if (variablesTypeRowId.includes(params.node.id ?? '')) return true;

          return !(
            Object.keys(params.node?.aggData ?? {}).length >= 1 ||
            Object.keys(params.node?.data ?? {}).length >= 1
          );
        },
        processCellCallback(params) {
          const colId = params.column.getColId();

          if (colId === 'y_type') {
            variableTypeFormatter(params.value);
          }

          if (colId === 'ag-Grid-AutoColumn') {
            if (
              ysInfoData?.market_share?.enable &&
              params?.node?.data?.y_type === 'marketshare'
            ) {
              return 'total';
            }

            return params.node?.data?.y_label ?? 'total';
          }

          if (
            params.value === undefined &&
            ysInfoData?.hierarchies.includes(colId)
          ) {
            let node = params.node;

            while (node?.parent) {
              if (node.field === colId) {
                return node?.key ?? 'total';
              }

              node = node.parent;
            }

            if (
              ysInfoData?.market_share?.enable &&
              params.node?.id?.startsWith('marketshare-')
            ) {
              const hierarchyIndex = ysInfoData.hierarchies.findIndex(
                (hierarchy) => hierarchy === colId,
              );

              if (
                hierarchyIndex !== -1 &&
                hierarchyIndex + 1 === params.node?.level
              ) {
                return params.node.data?.y_label ?? 'total';
              }
            }

            return 'total';
          }

          if (params.value) return params.value;

          if (params.node?.data?.[colId]) return params.node?.data?.[colId];

          if (transformation === 'variation' && params.node) {
            return getOriginalAdjustedValue(
              params.node,
              params.column.getColId(),
              forecastType,
            );
          }

          return null;
        },
        processGroupHeaderCallback(params) {
          const columnName = params.api.getDisplayNameForColumnGroup(
            params.columnGroup,
            null,
          );

          if (['Historical', 'Histórico'].includes(columnName))
            return translate('Historical');

          if (['Forecast', 'Projeção'].includes(columnName))
            return translate('Forecast');

          return columnName;
        },
        processHeaderCallback(params) {
          if (params.column.getColDef().field === 'y_label') {
            return translate('variable');
          }

          return (
            params.column?.getUserProvidedColDef()?.headerName ??
            params.column.getColDef().field!
          );
        },
      };

      tableRef.current!.api.exportDataAsExcel(config);
    }
  };

  const getDefaultDateInterval = (serieData: SerieData) => {
    const lastHistoricalDate = serieData.historical.dates.at(-1);
    const firstForecastDate = serieData.forecast?.dates[0];

    const selectedFrequency: Frequency =
      frequency === 'original' ? workspaceFrequency ?? 'monthly' : frequency;

    if (!lastHistoricalDate && !firstForecastDate) {
      if (frequency === 'yearly') {
        dispatch(changeWorkspaceOverviewYearlyPeriod([null, null]));
      } else if (frequency === 'quarterly') {
        dispatch(changeWorkspaceOverviewQuarterlyPeriod([null, null]));
      } else {
        dispatch(changeWorkspaceOverviewOriginalPeriod([null, null]));
      }

      return;
    }

    const firstDate = subMonths(
      new Date(
        (lastHistoricalDate ?? firstForecastDate ?? '').replace(':00Z', ''),
      ),
      selectedFrequency === 'monthly'
        ? frequencyIntervalInMonths[selectedFrequency].historical - 1
        : frequencyIntervalInMonths[selectedFrequency].historical,
    );

    const lastDate = addMonths(
      new Date(
        (firstForecastDate ?? lastHistoricalDate ?? '').replace(':00Z', ''),
      ),
      selectedFrequency === 'monthly'
        ? frequencyIntervalInMonths[selectedFrequency].forecast - 1
        : frequencyIntervalInMonths[selectedFrequency].forecast,
    );

    if (frequency === 'yearly') {
      dispatch(changeWorkspaceOverviewYearlyPeriod([firstDate, lastDate]));
    } else if (frequency === 'quarterly') {
      dispatch(changeWorkspaceOverviewQuarterlyPeriod([firstDate, lastDate]));
    } else {
      dispatch(changeWorkspaceOverviewOriginalPeriod([firstDate, lastDate]));
    }
  };

  const addDateColumn = (
    type: 'historicalColumn' | 'forecastColumn',
    historicalColumns: ColDef[],
    forecastColumns: ColDef[],
    allDates: Set<string>,
    date: string,
    isFirstY: boolean,
  ) => {
    const headerName = dateFormatter(
      date,
      frequency,
      workspaceFrequency,
      user.language,
    );

    const formattedDate = new Date(date);

    const periodFrequency =
      period[frequency === 'monthly' ? 'original' : frequency];

    let hide = true;

    if (periodFrequency[0] && periodFrequency[1]) {
      hide =
        formattedDate < periodFrequency[0] ||
        formattedDate > periodFrequency[1];
    }

    const column = {
      field: date,
      headerName,
      hide,
    };

    if (isFirstY) {
      if (type === 'historicalColumn') {
        historicalColumns.push({
          ...column,
          type: [type, 'defaultColumn'],
        });
      } else {
        forecastColumns.push({
          ...column,
          type: [type, 'defaultColumn'],
        });
      }
    } else if (!allDates.has(date)) {
      const historicalPosition = historicalColumns.findIndex(
        ({ field }) => new Date(field as string) > new Date(date),
      );

      if (historicalPosition !== -1) {
        historicalColumns.splice(historicalPosition, 0, {
          ...column,
          type: ['historicalColumn', 'defaultColumn'],
        });
      } else {
        const forecastPosition = forecastColumns.findIndex(
          ({ field }) => new Date(field as string) > new Date(date),
        );

        if (forecastPosition !== -1) {
          forecastColumns.splice(forecastPosition, 0, {
            ...column,
            type: ['forecastColumn', 'defaultColumn'],
          });
        } else {
          forecastColumns.push({
            ...column,
            type: ['forecastColumn', 'defaultColumn'],
          });
        }
      }
    }

    allDates.add(date);
  };

  const addSeriesDataToTable = (
    seriesData: SerieData[],
    allDates: Set<string>,
  ) => {
    const tableApi = tableRef.current?.api;

    if (tableApi) {
      for (let i = 0; i < seriesData.length; i++) {
        const isFirstY = allDates.size === 0 && i === 0;

        const data = seriesData[i];

        const updatedColumns = tableApi!.getGridOption('columnDefs') ?? [];

        const historical = (updatedColumns[0] as ColGroupDef)?.children ?? [];
        const forecast = (updatedColumns[1] as ColGroupDef)?.children ?? [];

        const row: RowData = {};

        const periodFrequency =
          period[frequency === 'monthly' ? 'original' : frequency];

        if (isFirstY && (!periodFrequency[0] || !periodFrequency[1])) {
          getDefaultDateInterval(data);
        }

        data.historical.dates.forEach((date, index) => {
          const updatedDate = date.replace(':00Z', '');

          addDateColumn(
            'historicalColumn',
            historical,
            forecast,
            allDates,
            updatedDate,
            isFirstY,
          );

          row[updatedDate] = data.historical.values[index];
        });

        const yDateIds: DateIds = {};

        data.forecast?.dates.forEach((date, index) => {
          const updatedDate = date.replace(':00Z', '');

          addDateColumn(
            'forecastColumn',
            historical,
            forecast,
            allDates,
            updatedDate,
            isFirstY,
          );

          row[updatedDate] = data.forecast?.values[index] ?? 0;
          yDateIds[updatedDate] = data.forecast?.ids?.[index] ?? 0;
        });

        const rowNode = tableApi!.getRowNode(data.serie);

        setYsDateIds((prev) => ({ ...prev, [data.serie]: yDateIds }));

        const forecastDates = data.forecast?.dates;

        let startDate: null | string = null;
        let endDate: null | string = null;

        if (forecastDates?.length) {
          startDate = forecastDates[0].replace(':00Z', '');
          endDate = forecastDates[forecastDates?.length - 1].replace(
            ':00Z',
            '',
          );
        }

        setStartEndYForecastDates((prev) => ({
          ...prev,
          [data.serie]: {
            startDate,
            endDate,
          },
        }));

        if (rowNode) {
          rowNode.updateData({
            ...rowNode.data,
            ...row,
          });
        }
      }
    }
  };

  const getAllSeriesData = async (signal: AbortSignal) => {
    const allDates: Set<string> = new Set();

    if (ysInfoData) {
      let index = 0;

      while (index < ysInfoData.ys.length && !signal.aborted) {
        const { originalYsPromises, adjustedYsPromises } = getYsPromises(
          index,
          index + 100,
        );

        try {
          const originalYsData = await Promise.all(originalYsPromises);
          const adjustedYsData = await Promise.all(adjustedYsPromises);

          if (!signal.aborted) {
            const data =
              forecastType === 'adjusted' ? adjustedYsData : originalYsData;

            addSeriesDataToTable(data, allDates);
          }
          // eslint-disable-next-line no-empty
        } catch {}

        index += 100;
      }

      index = 0;

      while (index < ysInfoData.ys.length && !signal.aborted) {
        try {
          const { aggsPromises } = getAggPromises(index, index + 100);
          await Promise.all(aggsPromises);
          // eslint-disable-next-line no-empty
        } catch {}

        index += 100;
      }

      if (!signal.aborted) {
        setIsLoadingSeriesData(false);

        getMarketShareData(signal);
      }
    }
  };

  useEffect(() => {
    const tableApi = tableRef?.current?.api;

    if (tableRendered) {
      setIsLoadingSeriesData(true);

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();

      getAllSeriesData(abortControllerRef.current.signal);
    }

    return () => {
      setYsDateIds({});
      setIsLoadingSeriesData(false);
      setMarketShareAggValues([]);

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      if (columns.length && tableApi) {
        const resetColumns = [...columns];
        resetColumns[0].children = [];
        resetColumns[1].children = [];

        tableApi.setGridOption('columnDefs', resetColumns);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableRendered,
    releaseSelected?.id,
    step?.number,
    frequency,
    transformation,
    inflation,
  ]);

  useEffect(() => {
    const getDefaultColumns = () => {
      if (ysInfoData) {
        const defaultColumns: (ColGroupDef | ColDef)[] = [
          {
            headerName: translate('Historical'),
            children: [],
          },
          {
            headerName: translate('Forecast'),
            children: [],
          },
        ];

        if (ysInfoData.market_share?.enable) {
          defaultColumns.push({
            field: 'y_type',
            headerName: translate('workspaceOverviewResultsType'),
            rowGroup: true,
            hide: false,
            filter: false,
            suppressHeaderMenuButton: true,
            pinned: 'left',
            flex: 1,
            width: 115,
            valueFormatter: (params) => variableTypeFormatter(params.value),
            type: 'variableTypeColumn',
            aggFunc: 'variableType',
          });
        }

        ysInfoData.hierarchies.forEach((key) => {
          defaultColumns.push({
            field: key,
            rowGroup: true,
            hide: true,
            filter: true,
          });
        });

        if (!ysInfoData.hierarchies.length) {
          defaultColumns.push({
            field: 'y_label',
            rowGroup: true,
            hide: true,
            filter: true,
          });
        }

        setColumns(defaultColumns);
      }
    };

    const getDefaultRows = () => {
      if (ysInfoData) {
        const rows: RowData[] = [];

        ysInfoData.ys.forEach((y) => {
          const data: RowData = {};

          if (ysInfoData.market_share?.enable) {
            data.y_type =
              y.type === 'others'
                ? translate('workspaceOverviewOthers').toLowerCase()
                : y.type;
          }

          ysInfoData.hierarchies.forEach((name, index) => {
            if (y.hierarchy.length - 1 >= index) {
              data[name] = y.hierarchy[index].value;
            }
          });

          data.y_label = y.y_label;
          data.row_id = y.y_label;

          rows.push(data);
        });

        if (ysInfoData.market_share?.enable && ysInfoData.series.length) {
          const marketShareSerie = ysInfoData.series.find(
            (serie) => serie.name === 'marketshare',
          );

          const allMarketShareRows: MarketShareRow[] = [];

          marketShareSerie?.hierarchies.forEach((hierarchy) => {
            const data: RowData = { y_type: 'marketshare' };
            let rowId = 'marketshare';

            const marketShareHierarchy: string[] = [];

            hierarchy.forEach(({ name, value }, index) => {
              rowId += `-${name}-${value}`;

              marketShareHierarchy.push(value);

              if (index === hierarchy.length - 1) {
                data.y_label = value;
                data.row_id = rowId;

                allMarketShareRows.push({
                  rowId,
                  isAggregation: false,
                  hierarchy: [...marketShareHierarchy],
                });
              } else {
                data[name] = value;
                allMarketShareRows.push({
                  isAggregation: true,
                  hierarchy: [...marketShareHierarchy],
                });
              }
            });

            rows.push(data);
          });

          setMarketShareRows(allMarketShareRows);
        }

        setRowData(rows);
      }
    };

    if (ysInfoData) {
      getDefaultColumns();
      getDefaultRows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ysInfoData]);

  useEffect(() => {
    const formatDateField = (columnChildren: ColDef[]) =>
      columnChildren.map((column: ColDef) => {
        if (!column.field) return { ...column };

        return {
          ...column,
          headerName: dateFormatter(
            column.field,
            frequency,
            workspaceFrequency,
            user.language,
          ),
        };
      });

    const translateAutoGroupColumn = () => {
      tableRef.current?.api.setGridOption('autoGroupColumnDef', {
        ...autoGroupColumnDef,
        headerName: translate('workspaceOverviewResultsGroup'),
      });

      const yTypeColumn = tableRef.current?.api.getColumn('y_type');

      if (yTypeColumn) {
        yTypeColumn.getColDef().headerName = translate(
          'workspaceOverviewResultsType',
        );
      }

      tableRef.current?.api.refreshHeader();
    };

    const translateColumns = () => {
      const allColumns = tableRef.current?.api.getGridOption('columnDefs');

      if (allColumns) {
        if (ysInfoData?.market_share?.enable) {
          (allColumns[2] as ColDef).headerName = translate(
            'workspaceOverviewResultsType',
          );
        }

        const historical = allColumns[0] as ColGroupDef;
        const forecast = allColumns[1] as ColGroupDef;

        historical.headerGroupComponent = () => (
          <div
            data-testid="historical-header"
            className="historical-forecast-header"
          >
            <LockSimple data-testid="historical-icon" />
            <p>{translate('Historical')}</p>
          </div>
        );

        historical.children = formatDateField(historical.children);

        forecast.headerGroupComponent = () => (
          <div
            data-testid="forecast-header"
            className="historical-forecast-header"
          >
            <PencilSimple data-testid="forecast-icon" />
            <p>{translate('Forecast')}</p>
          </div>
        );

        forecast.children = formatDateField(forecast.children);
      }

      tableRef.current?.api.setGridOption('columnDefs', allColumns);
    };

    if (tableRendered) {
      translateAutoGroupColumn();
      translateColumns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRendered, translate]);

  useEffect(() => {
    if (tableRendered && !isLoadingSeriesData) {
      let hasColumn = false;

      const allColumns = tableRef.current?.api.getGridOption('columnDefs');

      const allColumnsVisible: string[] = [];

      allColumns?.forEach((col) => {
        if (col.headerName) {
          const column = col as ColGroupDef;

          column?.children?.forEach(({ field }: ColDef, i) => {
            if (field) {
              hasColumn = true;

              const date = new Date(field);

              const periodFrequency =
                period[frequency === 'monthly' ? 'original' : frequency];

              if (
                periodFrequency[0] &&
                periodFrequency[1] &&
                date >= periodFrequency[0] &&
                date <= periodFrequency[1]
              ) {
                (column.children[i] as ColDef).hide = false;

                allColumnsVisible.push(
                  (column.children[i] as ColDef).field ?? '',
                );
              } else {
                (column.children[i] as ColDef).hide = true;
              }
            }
          });
        }
      });

      if (hasColumn) {
        tableRef.current?.api.setGridOption('columnDefs', allColumns);
      }
    }
  }, [tableRendered, isLoadingSeriesData, frequency, period]);

  useEffect(() => {
    if (isSaving) {
      let editingCell = tableRef.current?.api.getEditingCells() ?? [];

      while (editingCell.length > 0) {
        sleep(100);

        editingCell = tableRef.current?.api.getEditingCells() ?? [];
      }

      sleep(100);

      handleSaveEdition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (!editionExpired && editionModeEnabled) {
      interval = setInterval(() => setEditionExpired(true), ms('5 min'));
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [editionExpired, editionModeEnabled]);

  useEffect(() => {
    const updateStepStatusToAdjusting = async (stepNumber: number) => {
      if (step?.status === 'adjusting') {
        try {
          await apiWorkspace.put<Step>(
            `/workspaces/${workspaceId}/releases/${releaseSelected?.id}/steps/${stepNumber}`,
            { status: 'adjusting' },
          );

          // eslint-disable-next-line no-empty
        } catch {}

        if (step.number === stepNumber) {
          dispatch(
            changeWorkspaceOverviewStep({
              ...step,
              status: 'adjusting',
              selectedStatus: 'adjusting',
            }),
          );
        }

        refetchLogs();
      }
    };

    const checkIfStatusHasBeenUpdated = async () => {
      await sleep(ms('15s'));

      queryClient.refetchQueries({
        queryKey: ['workspace', workspaceId, 'releases', releaseSelected?.id],
        exact: true,
      });
    };

    const lastStep = releaseData?.data.steps?.length ?? 1;

    const isRemovingFirstStep =
      releaseData?.status === 'removing_step' &&
      releaseData?.data?.steps?.[0]?.status === 'baseline';

    if (
      (isDiscardingAdjustments ||
        releaseData?.status === 'discarding_step' ||
        isRemovingFirstStep ||
        isDiscadingFirstStep) &&
      !releaseIsLoading
    ) {
      if (
        (releaseData?.status === 'discarding_step' &&
          step?.number === lastStep) ||
        isRemovingFirstStep ||
        (isDiscadingFirstStep && tableRendered)
      ) {
        setTableRendered(false);
        setIsDiscardingAdjustments(true);
        checkIfStatusHasBeenUpdated();
      } else {
        if (step && lastStep === step.number) {
          handleUpdateData(lastStep, false);
        }

        if (step) {
          dispatch(
            changeLastLogTime({
              step: step.number,
              value: null,
            }),
          );
        }

        refetchLogs(lastStep);

        setIsDiscardingAdjustments(false);
        dispatch(changeIsDiscardingFirstStep(false));

        if (
          lastStep !== 1 &&
          releaseData?.data.steps?.[lastStep - 1]?.status === 'baseline'
        ) {
          try {
            updateStepStatusToAdjusting(lastStep);
            // eslint-disable-next-line no-empty
          } catch {}
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDiscardingAdjustments,
    releaseIsLoading,
    releaseData?.status,
    workspaceId,
    releaseSelected?.id,
    step?.number,
    isDiscadingFirstStep,
  ]);

  useEffect(() => {
    if (
      adjustedStepLogData?.records &&
      discardedAdjustedStepLogData?.records &&
      removedStepLogData?.records &&
      step?.number !== undefined
    ) {
      const joinArrayAdjustedAndDiscard = [
        ...adjustedStepLogData.records,
        ...discardedAdjustedStepLogData.records,
        ...removedStepLogData.records,
      ].sort(
        (a, b) =>
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
      );

      if (joinArrayAdjustedAndDiscard.length > 0) {
        const adjustedLastLogTime = new Date(
          joinArrayAdjustedAndDiscard[0].timestamp,
        ).getTime();

        if (typeof lastLogTimeByStep?.[step.number] !== 'number') {
          dispatch(
            changeLastLogTime({
              step: step.number,
              value: adjustedLastLogTime,
            }),
          );
        } else if (
          //@ts-expect-error: typescript bugou
          lastLogTimeByStep[step.number] < adjustedLastLogTime &&
          user.email !== joinArrayAdjustedAndDiscard[0].user &&
          !isDiscardingAdjustments
        ) {
          setButtonUpdateStatus('opened');
        } else {
          setButtonUpdateStatus(null);
        }
      } else if (lastLogTimeByStep?.[step.number] !== 0) {
        dispatch(
          changeLastLogTime({
            step: step.number,
            value: 0,
          }),
        );
        setButtonUpdateStatus(null);
      }
    }
  }, [
    adjustedStepLogData,
    discardedAdjustedStepLogData,
    removedStepLogData,
    dispatch,
    isDiscardingAdjustments,
    lastLogTimeByStep,
    step,
    user.email,
  ]);

  useEffect(() => {
    if (!ysInfoData) {
      setTableRendered(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ysInfoData]);

  useEffect(() => {
    (async () => {
      if (tableRendered && ysInfoData) {
        setTableRendered(false);

        await sleep(50);

        setTableRendered(true);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseSelected?.id]);

  useEffect(() => {
    if (releaseData?.data.approval_flow?.enable) {
      if (
        step?.selectedStatus === 'baseline' &&
        step.number === 1 &&
        step.status !== 'baseline'
      ) {
        dispatch(changeWorkspaceOverviewForecast('original'));
      } else {
        dispatch(changeWorkspaceOverviewForecast('adjusted'));
      }
    }
  }, [step, dispatch, releaseData?.data.approval_flow]);

  useEffect(() => {
    if (
      isDiscardingAdjustments &&
      step?.number === releaseData?.data.steps?.length
    ) {
      setTableRendered(false);
    }
  }, [step?.number, isDiscardingAdjustments, releaseData?.data.steps]);

  useEffect(() => {
    if (
      step &&
      (step.status === 'awaiting_approval' || step.status === 'approved') &&
      editionModeEnabled
    ) {
      dispatch(changeEditionModeEnabled(false));
    }

    if (step && step.status !== 'awaiting_approval' && reviewModeEnabled) {
      dispatch(changeReviewModeEnabled(false));
    }
  }, [step, editionModeEnabled, dispatch, reviewModeEnabled]);

  const monthlyWorkspace = workspaceFrequency === 'monthly';
  const releaseIsPreview = releaseSelected?.id === releasePreview;

  const isShowingAdjustedData = forecastType === 'adjusted';

  const stepStatusIsAdjusting = step?.status === 'adjusting';
  const stepStatusIsAwaitingApproval = step?.status === 'awaiting_approval';
  const stepStatusIsApproved = step?.status === 'approved';

  const hasDisapprovalMessage = !!step?.disapproval_messages?.length;
  const stepStatusIsDisapproved =
    !!stepStatusIsAdjusting && !!hasDisapprovalMessage;

  const userIsManager = userRole === 'manager';

  const isLoadingTableData = isLoadingSeriesData || isLoadingMarketShare;

  const hasNoAdjustments =
    new Date(updatedStepLogData?.records?.[0]?.timestamp ?? '5000-01-01') >
    new Date(adjustedStepLogData?.records?.[0]?.timestamp ?? '1000-01-01');

  const showMakeAdjustmentsButton =
    hasNoAdjustments &&
    stepStatusIsDisapproved &&
    !editionModeEnabled &&
    isShowingAdjustedData;

  const shouldUpdateData = buttonUpdateStatus === 'opened';

  const canEdit =
    frequency === 'original' &&
    monthlyWorkspace &&
    transformation === 'level' &&
    isShowingAdjustedData;

  const editionModeDisabled =
    releaseIsPreview ||
    loadingEnableEdition ||
    isLoadingTableData ||
    shouldUpdateData;

  const showEditionModeButtons =
    canEdit &&
    (!step || stepStatusIsAdjusting || step.status === 'baseline') &&
    !showMakeAdjustmentsButton;

  const showDiscardAdjustmentsAndSubmitApprovalButton =
    stepStatusIsAdjusting &&
    !editionModeEnabled &&
    isShowingAdjustedData &&
    !showMakeAdjustmentsButton;

  const showAwaitingApprovalTag =
    stepStatusIsAwaitingApproval && !userIsManager && isShowingAdjustedData;

  const showReviewScenarioButton =
    stepStatusIsAwaitingApproval &&
    userIsManager &&
    isShowingAdjustedData &&
    !reviewModeEnabled;

  const showDisapproveApproveButtons =
    reviewModeEnabled && isShowingAdjustedData;

  const showApprovedTag = stepStatusIsApproved && isShowingAdjustedData;

  const discardAdjustmentsDisabled = hasNoAdjustments || shouldUpdateData;

  const submitForApprovalDisabled = shouldUpdateData;
  const reviewScenarioDisabled = shouldUpdateData;

  const stepStatusHasMessage =
    (stepStatusIsAwaitingApproval && userIsManager) ||
    stepStatusIsApproved ||
    stepStatusIsDisapproved;

  const showFlowMessage = !releaseIsPreview && stepStatusHasMessage;
  const flowMessageInformation: LogRecord | undefined = stepStatusHasMessage
    ? updatedStepLogData?.records[0]
    : undefined;

  const flowMessagesByStatus = {
    baseline: '',
    adjusting: flowMessageInformation?.data.disapproval_messages?.[0],
    awaiting_approval:
      flowMessageInformation?.data.awaiting_approval_message?.[0],
    approved: flowMessageInformation?.data.approval_message?.[0],
  };

  const flowMessage = flowMessagesByStatus[step?.status ?? 'adjusting'];

  const tableStatus: TableStatus = !isShowingAdjustedData
    ? null
    : editionModeEnabled
    ? 'editing'
    : reviewModeEnabled
    ? 'reviewing'
    : step?.status === 'awaiting_approval'
    ? 'awaiting_approval'
    : step?.status === 'approved'
    ? 'approved'
    : hasNoAdjustments && stepStatusIsDisapproved
    ? 'disapproved'
    : null;

  const isLoadingYsInfo =
    ysInfoIsLoading || ysInfoIsFetching || !columns.length;

  return (
    <Container
      data-testid="results-description-card"
      className="containerLinear"
    >
      <Card
        textCard={translate('workspaceOverviewResultsTitle')}
        textDescription={translate('workspaceOverviewResultsDescription')}
        style={{ width: showFlowMessage ? 'calc(100% - 27rem)' : '100%' }}
      />

      <ButtonUpdate
        id="button-update-data"
        onClick={() => handleUpdateData()}
        status={buttonUpdateStatus}
        data-testid="button-update-data"
      >
        <ArrowsClockwise size={16} />
        {translate('workspaceOverviewUpdateData')}
      </ButtonUpdate>

      <HeaderRightContent>
        {showFlowMessage && (
          <FlowMessage
            isLoading={updatedStepLogIsLoading}
            message={flowMessage}
            author={flowMessageInformation?.user}
            dateTime={flowMessageInformation?.timestamp}
          />
        )}
      </HeaderRightContent>

      <SelectedFiltersExportContainer>
        <SelectedFilters filters={selectedFilters} />

        <DownloadButton
          buttonType="secondary"
          icon={<DownloadSimple />}
          onClick={handleDownloadTable}
          disabled={
            isLoadingSeriesData ||
            isLoadingMarketShare ||
            editionModeEnabled ||
            isLoadingYsInfo ||
            !tableRendered
          }
          data-testid="download-results-button"
          data-tooltip-id="results-table"
          data-tooltip-content={
            isLoadingSeriesData || isLoadingMarketShare
              ? translate('workspaceOverviewResultsLoadingData')
              : editionModeEnabled
              ? translate('workspaceOverviewResultsEditionBlockDownload')
              : undefined
          }
        >
          {translate('export')}
        </DownloadButton>
      </SelectedFiltersExportContainer>

      {ysInfoIsError ? (
        <Status
          type="error"
          title={translate('workspaceOverviewResultsErrorTitle')}
          description={translate('workspaceOverviewResultsErrorDescription')}
        />
      ) : isLoadingYsInfo ? (
        <ContainerSkeleton
          data-testid="loading-results-table"
          style={{ height: '22rem' }}
        />
      ) : isDiscardingAdjustments &&
        step?.number === releaseData?.data.steps?.length ? (
        // eslint-disable-next-line react/jsx-indent
        <DiscardingAdjustmentsContainer data-testid="loading-discarding-adjustments">
          <ContainerSkeleton style={{ height: 'fit-content' }} />

          <p>
            {translate('workspaceOverviewPlanningFlowDiscardingAdjustments')}
          </p>
        </DiscardingAdjustmentsContainer>
      ) : (
        <TableContainer status={tableStatus}>
          <TableButtonsContainer>
            {showAwaitingApprovalTag ? (
              <Tag
                data-testid="container-waiting-approval"
                className="waiting-approval-tag"
              >
                {translate('workspaceOverviewPlanningFlowWaitingApproval')}
              </Tag>
            ) : showReviewScenarioButton ? (
              <Button
                buttonType="primary"
                icon={<CheckCircle />}
                onClick={handleStartReview}
                disabled={reviewScenarioDisabled}
                data-testid="button-review-scenario"
                className="button-review-scenario"
                data-tooltip-id="results-table"
                data-tooltip-content={
                  buttonUpdateStatus === 'opened'
                    ? translate(
                        'workspaceOverviewUpdateDataToEnableReviewScenario',
                      )
                    : undefined
                }
              >
                {translate('workspaceOverviewPlanningFlowReviewScenario')}
              </Button>
            ) : showDisapproveApproveButtons ? (
              <>
                <Button
                  buttonType="secondary"
                  icon={<SignOut />}
                  onClick={handleExitReview}
                  data-testid="button-exit-review"
                  className="button-disapprove-or-exit"
                >
                  {translate('workspaceOverviewResultsDiscardEdition')}
                </Button>
                <Button
                  buttonType="secondary"
                  icon={<X />}
                  onClick={() => setShowDisapproveModal(true)}
                  data-testid="button-disapprove"
                  className="button-disapprove-or-exit"
                >
                  {translate('workspaceOverviewPlanningFlowDisapprove')}
                </Button>
                <Button
                  buttonType="primary"
                  icon={<CheckCircle />}
                  onClick={() => setShowApproveModal(true)}
                  data-testid="button-approve"
                  className="button-approve"
                >
                  {translate('workspaceOverviewPlanningFlowApprove')}
                </Button>
              </>
            ) : showApprovedTag ? (
              <Tag data-testid="container-approved" className="approved-tag">
                {translate('workspaceOverviewPlanningFlowApproved')}
              </Tag>
            ) : (
              showMakeAdjustmentsButton &&
              canEdit && (
                <Button
                  buttonType="primary"
                  icon={<PencilSimple />}
                  onClick={handleEnableEdition}
                  loading={loadingEnableEdition}
                  disabled={loadingEnableEdition || isLoadingSeriesData}
                  data-testid="button-new-adjustments"
                  className="button-new-adjustments"
                >
                  {translate('workspaceOverviewPlanningFlowMakeAdjustments')}
                </Button>
              )
            )}

            {showEditionModeButtons && (
              <>
                {!editionModeEnabled ? (
                  <Button
                    buttonType="secondary"
                    icon={<PencilSimple />}
                    onClick={handleEnableEdition}
                    loading={loadingEnableEdition}
                    disabled={editionModeDisabled}
                    data-testid="enable-edition-button"
                    className="enable-edition-button"
                    data-tooltip-id="results-table"
                    data-tooltip-content={
                      buttonUpdateStatus === 'opened'
                        ? translate(
                            'workspaceOverviewUpdateDataToEnableEditingMode',
                          )
                        : releaseIsPreview
                        ? translate(
                            'workspaceOverviewResultsEditionDisabledTooltip',
                          )
                        : isLoadingTableData
                        ? translate('workspaceOverviewResultsLoadingData')
                        : undefined
                    }
                  >
                    {translate('workspaceOverviewResultsEnableEditMode')}
                  </Button>
                ) : (
                  <>
                    <Button
                      buttonType="secondary"
                      icon={<SignOut />}
                      onClick={handleExitEdition}
                      data-testid="discard-edition-button"
                      className="discard-edition-button"
                    >
                      {translate('workspaceOverviewResultsDiscardEdition')}
                    </Button>
                    <Button
                      buttonType="primary"
                      icon={<FloppyDisk />}
                      onClick={() => setIsSaving(true)}
                      loading={isSaving}
                      disabled={invalidValues > 0 || isSaving}
                      data-testid="save-edition-button"
                      className="save-edition-button"
                    >
                      {translate('workspaceOverviewResultsSaveEdition')}
                    </Button>
                  </>
                )}
              </>
            )}

            {showDiscardAdjustmentsAndSubmitApprovalButton && (
              <>
                <Button
                  buttonType="secondary"
                  icon={<Trash />}
                  onClick={() => setShowDiscardAdjustmentsConfirmation(true)}
                  disabled={discardAdjustmentsDisabled}
                  data-testid="button-discard-adjustments"
                  className="button-discard-adjustments"
                  data-tooltip-id="results-table"
                  data-tooltip-content={
                    buttonUpdateStatus === 'opened'
                      ? translate(
                          'workspaceOverviewUpdateDataToEnableDiscardAdjustments',
                        )
                      : hasNoAdjustments
                      ? translate('workspaceOverviewPlanningFlowNoAdjustments')
                      : undefined
                  }
                >
                  {translate('workspaceOverviewPlanningFlowDiscardAdjustments')}
                </Button>

                <SubmitApprovalContainer>
                  <Button
                    buttonType="secondary"
                    icon={<PaperPlaneTilt />}
                    onClick={() => setShowRequestApproval(!showRequestApproval)}
                    disabled={submitForApprovalDisabled}
                    data-testid="button-submit-for-approval"
                    className="button-submit-for-approval"
                    data-tooltip-id="results-table"
                    data-tooltip-content={
                      buttonUpdateStatus === 'opened'
                        ? translate(
                            'workspaceOverviewUpdateDataToEnableSubmitForApproval',
                          )
                        : undefined
                    }
                  >
                    {translate('workspaceOverviewPlanningFlowSubmitApproval')}
                  </Button>

                  <RequestApproval
                    visible={showRequestApproval}
                    lockEdition={lockEdition}
                    onRequestApproval={updateStepStatus}
                    closeRequestApproval={() => setShowRequestApproval(false)}
                  />
                </SubmitApprovalContainer>
              </>
            )}
          </TableButtonsContainer>

          {invalidValues > 0 && (
            <TableError visible data-testid="invalid-values-error">
              <Warning weight="fill" />
              <p>{translate('workspaceOverviewResultsInvalidValue')}</p>
            </TableError>
          )}

          <AgGridTable
            animateRows={false}
            tableRef={tableRef}
            rowData={rowData}
            columnDefs={columns}
            autoGroupColumnDef={autoGroupColumnDef}
            defaultColDef={defaultColDef}
            aggFuncs={aggFuncs}
            getRowId={getRowId}
            getRowHeight={getRowHeight}
            checkDomLayoutType={tableRendered}
            onFirstDataRendered={() => setTableRendered(true)}
            groupRemoveSingleChildren={!ysInfoData?.hierarchies.length}
            groupDefaultExpanded={
              !ysInfoData?.aggregation?.enable
                ? -1
                : ysInfoData?.market_share?.enable
                ? 1
                : 0
            }
            rowClassRules={
              ysInfoData?.market_share?.enable ? rowClassRules : undefined
            }
            columnTypes={{
              defaultColumn: {
                aggFunc,
                valueFormatter: (params: ValueFormatterParams) =>
                  numberFormatter(params.value, user.language),
                editable: (params: EditableCallbackParams) =>
                  editable(
                    params.data.y_label,
                    new Date(params.colDef.field ?? ''),
                    params.node.id ?? '',
                  ),
                cellEditor: 'agNumberCellEditor',
                cellClass: defaultColumnCellClass,
                cellRenderer: (params: ICellRendererParams) => {
                  const isAggCell =
                    !params.data?.y_label && ysInfoData?.aggregation?.enable;

                  const id = `${params.node.id}-${params.colDef?.field}` ?? '';

                  const isMarketShare =
                    id.startsWith('row-group-y_type-marketshare') ||
                    !!params.node?.parent?.id?.startsWith(
                      'row-group-y_type-marketshare',
                    );

                  const isForecastCell =
                    (isAggCell &&
                      !!params.colDef?.type?.includes('forecastColumn')) ||
                    isForecast(
                      isMarketShare ? params.node.id : params.data?.y_label,
                      new Date(params.colDef?.field ?? ''),
                    );

                  const isVariationAgg =
                    isAggCell && transformation === 'variation';

                  const needBaselineValue = isForecastCell || isVariationAgg;

                  const loadingData =
                    isLoadingSeriesData ||
                    (isLoadingMarketShare && isMarketShare);

                  if (!needBaselineValue || loadingData) {
                    const value =
                      loadingData || params.value === null
                        ? undefined
                        : params.value;

                    return (
                      <ValueCell
                        id={id}
                        baselineValue={value}
                        adjustedValue={value}
                        isForecast={false}
                        showValueError={false}
                        isMarketShare={isMarketShare}
                        showBaselineValue={false}
                      />
                    );
                  }

                  const baselineValue = getOriginalAdjustedValue(
                    params.node,
                    params.colDef?.field ?? '',
                    'original',
                  );

                  if (
                    isVariationAgg &&
                    params.value !== baselineValue &&
                    params.value === null
                  ) {
                    const rowNode = params.api.getRowNode(
                      params.node.id ?? '',
                    )!;

                    rowNode.setDataValue(
                      params.colDef?.field ?? '',
                      baselineValue,
                    );
                  }

                  let adjustedValue = isVariationAgg
                    ? getOriginalAdjustedValue(
                        params.node,
                        params.colDef?.field ?? '',
                        'adjusted',
                      )
                    : params.value;

                  if (
                    step?.number === 1 &&
                    step.selectedStatus === 'baseline' &&
                    step.status !== 'baseline'
                  ) {
                    adjustedValue = baselineValue;
                  }

                  return (
                    <ValueCell
                      id={id}
                      baselineValue={baselineValue}
                      adjustedValue={adjustedValue}
                      isForecast={isForecastCell}
                      showValueError={editionModeEnabled}
                      isMarketShare={isMarketShare}
                      showBaselineValue={
                        editionModeEnabled ||
                        (reviewModeEnabled && isShowingAdjustedData) ||
                        (stepStatusIsAwaitingApproval && !userIsManager) ||
                        (stepStatusIsApproved && isShowingAdjustedData)
                      }
                    />
                  );
                },
              },
              historicalColumn: {},
              forecastColumn: {},
              variableTypeColumn: {},
            }}
            onCellEditingStopped={(params) =>
              handleEditedValue(
                params.data.y_label,
                params.colDef.field ?? '',
                params.newValue,
                params.oldValue,
                params.node.id ?? '',
              )
            }
            excelStyles={excelStyles}
            groupAllowUnbalanced
            suppressGroupRowsSticky
          />
        </TableContainer>
      )}

      {(!!showUserIsEditingModal || showWorkspaceIsPublishing) && (
        <NoPermissionToEditModal
          setVisible={() => {
            setShowUserIsEditingModal('');
            setShowWorkspaceIsPublishing(false);
          }}
          emailEditing={showUserIsEditingModal}
          errorDescription={
            editionModeEnabled
              ? translate(
                  'workspaceOverviewResultsEditionLockedDescription',
                ).replace('XXX', showUserIsEditingModal)
              : showWorkspaceIsPublishing
              ? translate('workspaceOverviewResultsWorkspaceIsPublishing')
              : undefined
          }
        />
      )}

      {editionExpired && editionModeEnabled && (
        <SessionExpiredModal
          handleBlockEdition={handleEnableEdition}
          loadingBlockEdition={loadingEnableEdition}
          handleDiscardChanges={handleExitEdition}
        />
      )}

      {showDiscardAdjustmentsConfirmation && (
        <ActionConfirmationModal
          setVisible={setShowDiscardAdjustmentsConfirmation}
          title={translate(
            'workspaceOverviewDiscardAdjustmentsConfirmationModalTitle',
          )}
          description={translate(
            step?.number === 1
              ? 'workspaceOverviewDiscardAdjustmentsConfirmationModalDescriptionFirstStep'
              : 'workspaceOverviewDiscardAdjustmentsConfirmationModalDescriptionOtherStep',
          )}
          onConfirm={handleDiscardAllAdjustments}
        />
      )}

      {showApproveModal && (
        <ApproveOrDisapproveModal
          approveOrDisapprove="approve"
          onConfirm={handleApproveReview}
          onCancel={() => setShowApproveModal(false)}
        />
      )}

      {showDisapproveModal && (
        <ApproveOrDisapproveModal
          approveOrDisapprove="disapprove"
          onConfirm={handleDisapproveReview}
          onCancel={() => setShowDisapproveModal(false)}
        />
      )}

      {showUpdateStatusError && (
        <Modal
          visible
          setVisible={setShowUpdateStatusError}
          style={{ width: '30rem' }}
          dataCy="modal-update-status-error"
        >
          <Status
            type="cloudWarning"
            title={translate('workspaceOverviewPlanningFlowUpdateErrorTitle')}
            description={translate(
              'workspaceOverviewPlanningFlowUpdateErrorDescription',
            )}
            isModal
          />

          <ModalFooter>
            <Button
              buttonType="primary"
              onClick={() => setShowUpdateStatusError(false)}
            >
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {unsavedYs.length > 0 && (
        <UnsavedYsModal ys={unsavedYs} setVisible={() => setUnsavedYs([])} />
      )}

      <Tooltip id="results-table" className="customTooltipTheme" />
    </Container>
  );
});
