import styled, { css } from 'styled-components';

type ContainerProps = {
  error: boolean;
};

export const Container = styled.div<ContainerProps>`
  width: calc(100% + 1.875rem);
  height: 100%;

  margin: 0 -1.9rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 1px dashed ${({ theme }) => theme.colors.purple4};
  background: linear-gradient(
      0deg,
      rgba(159, 122, 234, 0.08) 0%,
      rgba(159, 122, 234, 0.08) 100%
    ),
    #fff;

  .baseline-value {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;

    font-size: 0.625rem;
    font-weight: 400;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.purple4};
  }

  .adjusted-value {
    color: ${({ theme }) => theme.colors.purple4};

    font-weight: 500;

    line-height: 150%;
  }

  ${({ error }) =>
    error &&
    css`
      .baseline-value,
      .adjusted-value {
        color: ${({ theme }) => theme.colors.red6};
      }
    `}
`;
