import { TableV2 } from 'src/components/TableV2';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 5rem;
  margin-right: 5rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: stretch;

  gap: 1rem;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;

  gap: 1rem;
`;

export const InfoContainer = styled.h4`
  color: ${({ theme }) => theme.colors.gray6};

  font-family: Inter;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

interface MenuOverlayProps {
  visible: boolean;
}

export const MenuOverlay = styled.div<MenuOverlayProps>`
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const RoundedButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  gap: 0.5rem;
`;

export const ExportButton = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;

  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  height: 2.375rem;

  border-radius: 624.9375rem;
  border: 1px solid ${({ theme }) => theme.colors.gray2};

  color: ${({ theme }) => theme.colors.gray5};

  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  svg {
    width: 1rem;
    height: 1rem;

    color: ${({ theme }) => theme.colors.gray4};
  }

  :disabled {
    cursor: not-allowed;
  }

  :hover {
    background: ${({ theme }) => theme.colors.gray0};
    color: ${({ theme }) => theme.colors.gray6};

    svg {
      color: ${({ theme }) => theme.colors.gray6};
    }
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;

  color: ${({ theme }) => theme.colors.gray5};
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const ContentButtonExport = styled.div`
  position: relative;
`;

export const ContainerTabs = styled.div`
  display: flex;
  padding: 16px 24px;
  align-items: center;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-top: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  background: #fff;

  flex-wrap: wrap;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  padding: 16px 0px 8px;
  flex-direction: row;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;

  justify-content: end;
`;

export const ButtonsContainerSkeleton = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: end;
  align-items: stretch;
  align-content: stretch;

  gap: 2rem;
`;

export const DatePickerCopyCodes = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  gap: 1rem;
`;

type ContainerTableProps = {
  isColAsDate?: boolean;
  firstThWidth: number;
  firstThHeight: number;
};

export const ContainerTable = styled(TableV2.Container)<ContainerTableProps>`
  margin-top: 0.5rem;
  max-height: 684px;
  overflow-y: auto;

  table {
    overflow: unset;
  }

  table tr td {
    font-size: 0.75rem !important;
    padding: 0.3125rem 0.3125rem;
  }

  th {
    position: sticky;
    top: 0;
    z-index: 50;
  }

  thead {
    tr:first-child {
      th:first-child {
        left: 0;
        z-index: 52;
      }
    }
  }

  td:first-child {
    position: sticky;
    left: 0;
    z-index: 50;
  }

  tbody {
    td:first-child {
      background-color: ${({ theme }) => theme.colors.white};
    }
    > tr:nth-child(even) {
      td:first-child {
        background-color: ${({ theme }) => theme.colors.gray0} !important;
      }
    }
  }

  .ThSummary {
    font-weight: 700;
  }

  ${({ isColAsDate, firstThWidth, firstThHeight }) =>
    isColAsDate
      ? css`
          tr:first-child th {
            border-bottom: none;
          }

          tr:nth-child(2) th {
            font-size: 10px;
            color: ${({ theme }) => theme.colors.gray5} !important;
          }

          thead {
            tr:nth-child(2) {
              position: sticky;
              top: ${() => firstThHeight}px;
              z-index: 52;
            }

            tr:nth-child(2) th:first-child {
              position: sticky;
              z-index: 54;
              left: 0;
            }
          }
        `
      : css`
          thead {
            tr:first-child {
              th:nth-child(2) {
                position: sticky;
                top: 0;
                left: ${() => firstThWidth}px;
                z-index: 52;
              }
            }
          }

          td:nth-child(2) {
            position: sticky;
            left: ${() => firstThWidth}px;
            z-index: 50;
          }

          tbody {
            td:nth-child(2) {
              background-color: ${({ theme }) => theme.colors.white};
            }
            > tr:nth-child(even) {
              td:nth-child(2) {
                background-color: ${({ theme }) =>
                  theme.colors.gray0} !important;
              }
            }
          }
        `};
`;

export const ContentPeriod = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

type ThColumnProps = {
  width: number;
  isSummary: boolean;
};

export const ThColumn = styled(TableV2.Th)<ThColumnProps>`
  min-width: ${({ width }) => `${width}px`};
  max-width: ${({ width }) => `${width}px`};
  width: auto;
  top: ${({ isSummary }) => (isSummary ? '47px' : '0px')} !important;
  word-wrap: break-word;
`;
