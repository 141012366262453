import React, { useEffect, useState } from 'react';

import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/Button';
import { CheckBox } from 'src/components/CheckBox';
import { Modal } from 'src/components/Modal';
import { ContainerWarning } from 'src/models/components/ContainerWarning';
import { RootState } from 'src/redux/store';
import { ArrowRight, LockSimple, StarFour } from 'phosphor-react';

import { SelectOptions, SeriesIndicator } from '../../types';
import { SendToGroupModal } from '../SendToGroups';
import {
  ButtonDiv,
  Container,
  Content,
  CustomDivWarning,
  Description,
  Filter,
  FilterContent,
  FilterSeries,
} from './styles';
import {
  LockedAccessIndicator,
  WarningLockedResource,
} from '../ExportSeries/styles';

interface DefaultOptions {
  region: string | undefined;
  frequency: string | undefined;
  primary: string | undefined;
  secondary: string | undefined;
}

interface FilterIndicatorSeriesProps {
  visible: boolean;
  setVisible(visible: boolean): void;
  series: SeriesIndicator[] | undefined;
  regionOption: SelectOptions[] | undefined;
  aggregationOption: SelectOptions[] | undefined;
  transformationPrimaryOption: SelectOptions[] | undefined;
  transformationSecondaryOption: SelectOptions[] | undefined;
  defaultOptions: DefaultOptions;
}

interface WarningMessageProps {
  regionValue: string;
  regionName: string;
  errorFilterName: string;
  errorFilterValue: string;
}

interface SelectedSeries {
  region: string;
  aggregations: {
    name: string;
    primarys: {
      name: string;
      seconds: {
        name: string;
        data?: SeriesIndicator[];
      }[];
    }[];
  }[];
}

const FilterIndicatorSeries: React.FC<FilterIndicatorSeriesProps> = ({
  visible,
  setVisible,
  regionOption,
  aggregationOption,
  transformationPrimaryOption,
  transformationSecondaryOption,
  series,
  defaultOptions,
}) => {
  const [regionChecked, setRegionChecked] = useState<string[]>([]);
  const [aggregationChecked, setAggregationChecked] = useState<string[]>([]);
  const [primaryChecked, setPrimaryChecked] = useState<string[]>([]);
  const [secondaryChecked, setSecondaryChecked] = useState<string[]>([]);

  const [modalSendToGroup, setModalSendToGroup] = useState<boolean>(false);

  const [warningMessage, setWarningMessage] = useState<WarningMessageProps[]>(
    [],
  );

  const [codeSeries, setCodeSeries] = useState<string[]>([]);

  const { t: translate } = useTranslation();
  const { language, isFreemium } = useSelector(
    (state: RootState) => state.auth.user,
  );

  useEffect(() => {
    if (defaultOptions.region) {
      setRegionChecked([defaultOptions.region]);
    }
    if (defaultOptions.frequency) {
      setAggregationChecked([defaultOptions.frequency]);
    }
    if (defaultOptions.primary) {
      setPrimaryChecked([defaultOptions.primary]);
    }
    if (defaultOptions.secondary) {
      setSecondaryChecked([defaultOptions.secondary]);
    }
  }, [defaultOptions]);

  useEffect(() => {
    if (!series) {
      return;
    }

    const selectedFiltersRegionDefined: SelectedSeries[] = regionChecked.map(
      (region) => ({
        region,
        aggregations:
          aggregationOption?.map((aggregation) => ({
            name: aggregation.value,
            primarys:
              transformationPrimaryOption?.map((primary) => ({
                name: primary.value,
                seconds:
                  transformationSecondaryOption?.map((secondary) => ({
                    name: secondary.value,
                  })) ?? [],
              })) ?? [],
          })) ?? [],
      }),
    );

    selectedFiltersRegionDefined.forEach((region) => {
      region.aggregations.forEach((aggregation) => {
        aggregation.primarys.forEach((primary) => {
          primary.seconds.forEach((second) => {
            second.data = series.filter(
              (serie) =>
                serie.region['en-us'] === region.region &&
                serie.aggregation['en-us'] === aggregation.name &&
                serie.primary_transformation['en-us'] === primary.name &&
                serie.second_transformation['en-us'] === second.name,
            );
          });
        });
      });
    });

    const selectedFiltersRegion: SelectedSeries[] = regionChecked.map(
      (region) => ({
        region,
        aggregations:
          aggregationChecked?.map((aggregation) => ({
            name: aggregation,
            primarys:
              primaryChecked?.map((primary) => ({
                name: primary,
                seconds:
                  secondaryChecked?.map((secondary) => ({
                    name: secondary,
                  })) ?? [],
              })) ?? [],
          })) ?? [],
      }),
    );

    selectedFiltersRegion.forEach((region) => {
      region.aggregations.forEach((aggregation) => {
        aggregation.primarys.forEach((primary) => {
          primary.seconds.forEach((second) => {
            second.data = series.filter(
              (serie) =>
                serie.region['en-us'] === region.region &&
                serie.aggregation['en-us'] === aggregation.name &&
                serie.primary_transformation['en-us'] === primary.name &&
                serie.second_transformation['en-us'] === second.name,
            );
          });
        });
      });
    });

    selectedFiltersRegion.forEach((region) => {
      region.aggregations.forEach((aggregation) => {
        if (
          aggregation.primarys.flatMap((primary) =>
            primary.seconds.flatMap((second) => second.data),
          ).length ||
          selectedFiltersRegionDefined
            .find((regionDefinid) => regionDefinid.region === region.region)
            ?.aggregations.find(
              (aggregationDefinid) =>
                aggregationDefinid.name === aggregation.name,
            )
            ?.primarys.flatMap((primaryDefinid) =>
              primaryDefinid.seconds.flatMap(
                (secondDefinid) => secondDefinid.data,
              ),
            ).length
        ) {
          aggregation.primarys.forEach((primary) => {
            if (
              primary.seconds.flatMap((second) => second.data).length ||
              selectedFiltersRegionDefined
                .find((regionDefinid) => regionDefinid.region === region.region)
                ?.aggregations.find(
                  (aggregationDefinid) =>
                    aggregationDefinid.name === aggregation.name,
                )
                ?.primarys.find(
                  (primaryDefinid) => primaryDefinid.name === primary.name,
                )
                ?.seconds.flatMap((secondDefinid) => secondDefinid.data).length
            ) {
              primary.seconds.forEach((second) => {
                if (!second.data?.length) {
                  setWarningMessage((oldWarningMessage) => {
                    if (
                      !oldWarningMessage.some(
                        (warningMessageAux) =>
                          warningMessageAux.regionValue === region.region &&
                          warningMessageAux.errorFilterValue === second.name,
                      )
                    ) {
                      const regionSelected = regionOption?.find(
                        (regionAux) => regionAux.value === region.region,
                      );
                      const secondarySelected =
                        transformationSecondaryOption?.find(
                          (secondaryAux) => secondaryAux.value === second.name,
                        );

                      if (regionSelected && secondarySelected) {
                        return [
                          ...oldWarningMessage,
                          {
                            regionValue: region.region,
                            regionName:
                              regionSelected?.label[language] ??
                              regionSelected?.label['en-us'],
                            errorFilterValue: second.name,
                            errorFilterName:
                              secondarySelected?.label[language] ??
                              secondarySelected?.label['en-us'],
                          },
                        ];
                      }
                    }
                    return oldWarningMessage;
                  });
                }
              });
            } else {
              setWarningMessage((oldWarningMessage) => {
                if (
                  !oldWarningMessage.some(
                    (warningMessageAux) =>
                      warningMessageAux.regionValue === region.region &&
                      warningMessageAux.errorFilterValue === primary.name,
                  )
                ) {
                  const regionSelected = regionOption?.find(
                    (regionAux) => regionAux.value === region.region,
                  );
                  const primarySelected = transformationPrimaryOption?.find(
                    (primaryAux) => primaryAux.value === primary.name,
                  );
                  if (regionSelected && primarySelected) {
                    return [
                      ...oldWarningMessage,
                      {
                        regionValue: region.region,
                        regionName:
                          regionSelected?.label[language] ??
                          regionSelected?.label['en-us'],
                        errorFilterValue: primary.name,
                        errorFilterName:
                          primarySelected?.label[language] ??
                          primarySelected?.label['en-us'],
                      },
                    ];
                  }
                }
                return oldWarningMessage;
              });
            }
          });
        } else {
          setWarningMessage((oldWarningMessage) => {
            if (
              !oldWarningMessage.some(
                (warningMessageAux) =>
                  warningMessageAux.regionValue === region.region &&
                  warningMessageAux.errorFilterValue === aggregation.name,
              )
            ) {
              const regionSelected = regionOption?.find(
                (regionAux) => regionAux.value === region.region,
              );
              const aggregationSelected = aggregationOption?.find(
                (aggregationAux) => aggregationAux.value === aggregation.name,
              );
              if (regionSelected && aggregationSelected) {
                return [
                  ...oldWarningMessage,
                  {
                    regionValue: region.region,
                    regionName:
                      regionSelected?.label[language] ??
                      regionSelected?.label['en-us'],
                    errorFilterValue: aggregation.name,
                    errorFilterName:
                      aggregationSelected?.label[language] ??
                      aggregationSelected?.label['en-us'],
                  },
                ];
              }
            }
            return oldWarningMessage;
          });
        }
      });
    });

    const codeSeriesAux = selectedFiltersRegion.flatMap((regionSelect) =>
      regionSelect.aggregations.flatMap((aggregationSelect) =>
        aggregationSelect.primarys.flatMap((primarySelect) =>
          primarySelect.seconds.flatMap(
            (secondSelect) =>
              secondSelect.data?.map((serieData) => serieData.code) ?? [],
          ),
        ),
      ),
    );

    setCodeSeries(codeSeriesAux);

    return () => {
      setWarningMessage([]);
    };
  }, [
    regionChecked,
    series,
    aggregationChecked,
    primaryChecked,
    secondaryChecked,
    aggregationOption,
    transformationPrimaryOption,
    transformationSecondaryOption,
    language,
    regionOption,
  ]);

  const handleSelectRegion = (value: string) => {
    if (regionChecked.includes(value)) {
      setRegionChecked(regionChecked.filter((region) => region !== value));
    } else {
      setRegionChecked([...regionChecked, value]);
    }
  };

  const handleSelectAggregation = (value: string) => {
    if (aggregationChecked.includes(value)) {
      setAggregationChecked(
        aggregationChecked.filter((aggregation) => aggregation !== value),
      );
    } else {
      setAggregationChecked([...aggregationChecked, value]);
    }
  };

  const handleSelectPrimary = (value: string) => {
    if (primaryChecked.includes(value)) {
      setPrimaryChecked(primaryChecked.filter((primary) => primary !== value));
    } else {
      setPrimaryChecked([...primaryChecked, value]);
    }
  };

  const handleSelectSecondary = (value: string) => {
    if (secondaryChecked.includes(value)) {
      setSecondaryChecked(
        secondaryChecked.filter((secondary) => secondary !== value),
      );
    } else {
      setSecondaryChecked([...secondaryChecked, value]);
    }
  };

  const showWarningToUpgrade = !!series?.filter(
    (serie) =>
      serie.access_type === 'premium' || serie.access_type === 'default',
  ).length;

  return (
    <>
      <Modal visible={visible} setVisible={setVisible}>
        <Container
          style={{ maxWidth: '1280px' }}
          data-testid="container-filter"
        >
          <Content>
            <div>
              <Description>
                <h2>{translate('filterSelectOpenings')}</h2>
                <p>{translate('filterFinishedContinue')}</p>
              </Description>
              {isFreemium && showWarningToUpgrade && (
                <WarningLockedResource
                  data-testid="warning-upgrade-plan"
                  onClick={() =>
                    window.open(
                      `https://4intelligence.ai/freemium/upgrade`,
                      '_blank',
                    )
                  }
                >
                  <StarFour size="1.25rem" />
                  <p>{parse(translate('warningToUpgrade'))}</p>
                  <ArrowRight size="1.25rem" />
                </WarningLockedResource>
              )}
              <FilterContent>
                <Filter style={{ marginLeft: '0px' }} data-cy="region">
                  {isFreemium ? (
                    <CheckBox
                      data-testid="checkbox-all-region-freemium"
                      label={translate('filterRegion')}
                      onChange={({ target }) =>
                        regionOption && target.checked
                          ? setRegionChecked(
                              regionOption
                                .filter(
                                  (region) =>
                                    region.access_type === 'freemium' ||
                                    region.access_type === 'private',
                                )
                                .map((region) => region.value),
                            )
                          : setRegionChecked([])
                      }
                      checked={
                        regionChecked.length ===
                        regionOption?.filter(
                          (region) =>
                            region.access_type === 'freemium' ||
                            region.access_type === 'private',
                        )?.length
                      }
                    />
                  ) : (
                    <CheckBox
                      data-testid="checkbox-all-region"
                      label={translate('filterRegion')}
                      onChange={({ target }) =>
                        regionOption && target.checked
                          ? setRegionChecked(
                              regionOption.map((region) => region.value),
                            )
                          : setRegionChecked([])
                      }
                      checked={regionChecked.length === regionOption?.length}
                    />
                  )}
                  <FilterSeries>
                    {regionOption?.map((region) =>
                      isFreemium ? (
                        <>
                          {region.access_type === 'freemium' ||
                          region.access_type === 'private' ? (
                            <CheckBox
                              data-testid={`region-freemium-${region.label[
                                'en-us'
                              ]
                                .replaceAll(' ', '-')
                                .toLocaleLowerCase()}`}
                              key={region.label['en-us']}
                              label={
                                region.label[language] ?? region.label['en-us']
                              }
                              style={{ marginTop: '16px' }}
                              onChange={() => handleSelectRegion(region.value)}
                              checked={regionChecked.some(
                                (key) => key === region.value,
                              )}
                            />
                          ) : (
                            <LockedAccessIndicator
                              data-testid={`region-locked-${region.label[
                                'en-us'
                              ]
                                .replaceAll(' ', '-')
                                .toLocaleLowerCase()}`}
                              key={region.label['en-us']}
                            >
                              <LockSimple size="1.25rem" />
                              <p>
                                {region.label[language] ??
                                  region.label['en-us']}
                              </p>
                            </LockedAccessIndicator>
                          )}
                        </>
                      ) : (
                        <CheckBox
                          data-testid={`region-${region.label['en-us']
                            .replaceAll(' ', '-')
                            .toLocaleLowerCase()}`}
                          key={region.label['en-us']}
                          label={
                            region.label[language] ?? region.label['en-us']
                          }
                          style={{ marginTop: '16px' }}
                          onChange={() => handleSelectRegion(region.value)}
                          checked={regionChecked.some(
                            (key) => key === region.value,
                          )}
                        />
                      ),
                    )}
                  </FilterSeries>
                </Filter>
                <Filter data-cy="frequency">
                  <CheckBox
                    data-testid="checkbox-all-aggregation"
                    label={translate('filterFrequency')}
                    onChange={({ target }) =>
                      aggregationOption && target.checked
                        ? setAggregationChecked(
                            aggregationOption.map(
                              (aggregation) => aggregation.value,
                            ),
                          )
                        : setAggregationChecked([])
                    }
                    checked={
                      aggregationChecked.length === aggregationOption?.length
                    }
                  />
                  <FilterSeries>
                    {aggregationOption?.map((aggregation) => (
                      <CheckBox
                        data-testid={`aggregation-${aggregation.label['en-us']
                          .replaceAll(' ', '-')
                          .toLocaleLowerCase()}`}
                        key={aggregation.label['en-us']}
                        label={
                          aggregation.label[language] ??
                          aggregation.label['en-us']
                        }
                        style={{ marginTop: '16px' }}
                        onChange={() =>
                          handleSelectAggregation(aggregation.value)
                        }
                        checked={aggregationChecked.some(
                          (key) => key === aggregation.value,
                        )}
                      />
                    ))}
                  </FilterSeries>
                </Filter>
                <Filter data-cy="primaryTransformation">
                  <CheckBox
                    data-testid="checkbox-all-primary"
                    label={translate('filterPrimaryTransformation')}
                    onChange={({ target }) =>
                      transformationPrimaryOption && target.checked
                        ? setPrimaryChecked(
                            transformationPrimaryOption.map(
                              (primary) => primary.value,
                            ),
                          )
                        : setPrimaryChecked([])
                    }
                    checked={
                      primaryChecked.length ===
                      transformationPrimaryOption?.length
                    }
                  />
                  <FilterSeries>
                    {transformationPrimaryOption?.map(
                      (primaryTransformation) => (
                        <CheckBox
                          data-testid={`primary-${primaryTransformation.label[
                            'en-us'
                          ]
                            .replaceAll(' ', '-')
                            .toLocaleLowerCase()}`}
                          key={primaryTransformation.label['en-us']}
                          label={
                            primaryTransformation.label[language] ??
                            primaryTransformation.label['en-us']
                          }
                          style={{ marginTop: '16px' }}
                          onChange={() =>
                            handleSelectPrimary(primaryTransformation.value)
                          }
                          checked={primaryChecked.some(
                            (key) => key === primaryTransformation.value,
                          )}
                        />
                      ),
                    )}
                  </FilterSeries>
                </Filter>
                <Filter
                  style={{ border: 'unset' }}
                  data-cy="secondaryTransformation"
                >
                  <CheckBox
                    data-testid="checkbox-all-secondary"
                    label={translate('filterSecondaryTransformation')}
                    onChange={({ target }) =>
                      transformationSecondaryOption && target.checked
                        ? setSecondaryChecked(
                            transformationSecondaryOption.map(
                              (secondary) => secondary.value,
                            ),
                          )
                        : setSecondaryChecked([])
                    }
                    checked={
                      secondaryChecked.length ===
                      transformationSecondaryOption?.length
                    }
                  />
                  <FilterSeries>
                    {transformationSecondaryOption?.map(
                      (secondaryTransformation) => (
                        <CheckBox
                          data-testid={`secondary-${secondaryTransformation.label[
                            'en-us'
                          ]
                            .replaceAll(' ', '-')
                            .toLocaleLowerCase()}`}
                          key={secondaryTransformation.label['en-us']}
                          label={
                            secondaryTransformation.label[language] ??
                            secondaryTransformation.label['en-us']
                          }
                          style={{ marginTop: '16px' }}
                          onChange={() =>
                            handleSelectSecondary(secondaryTransformation.value)
                          }
                          checked={secondaryChecked.some(
                            (key) => key === secondaryTransformation.value,
                          )}
                        />
                      ),
                    )}
                  </FilterSeries>
                </Filter>
              </FilterContent>
            </div>
          </Content>
          {regionChecked.length < 1 ||
            aggregationChecked.length < 1 ||
            primaryChecked.length < 1 ||
            secondaryChecked.length < 1 ||
            (warningMessage.length > 0 && (
              <ContainerWarning
                data-testid="divWarning"
                data-cy="divWarning"
                visible
                style={{ maxWidth: '431px' }}
              >
                <CustomDivWarning>
                  <ul>
                    {warningMessage.map((message) => (
                      <li key={message.regionValue + message.errorFilterValue}>
                        {translate('filterWarningRegionNoOpening')
                          .replace('XXX', message.regionName)
                          .replace('YYY', message.errorFilterName)}
                      </li>
                    ))}
                  </ul>
                </CustomDivWarning>
              </ContainerWarning>
            ))}
          <ButtonDiv>
            <p>
              {parse(
                translate('filterNumberSeriesSelected')
                  .replace('XXX', codeSeries?.length.toString())
                  .replace(
                    'YYY',
                    series?.length ? series.length.toString() : '',
                  ),
              )}
            </p>
            <div>
              <Button
                data-testid="button-cancel"
                data-cy="button-cancel"
                buttonType="naked"
                onClick={() => {
                  setVisible(false);
                  setRegionChecked([]);
                }}
                style={{ marginRight: '2rem' }}
              >
                {translate('filterButtonCancel')}
              </Button>
              <Button
                buttonType="primary"
                data-testid="button-continue"
                data-cy="button-continue"
                onClick={() => {
                  setModalSendToGroup(true);
                }}
                disabled={
                  regionChecked.length < 1 ||
                  aggregationChecked.length < 1 ||
                  primaryChecked.length < 1 ||
                  secondaryChecked.length < 1 ||
                  codeSeries.length === 0
                }
              >
                {translate('filterButtonContinue')}
              </Button>
            </div>
          </ButtonDiv>
        </Container>
      </Modal>

      {modalSendToGroup && (
        <SendToGroupModal
          visible={modalSendToGroup}
          setVisible={() => {
            setModalSendToGroup(false);
            setVisible(false);
          }}
          series={codeSeries}
        />
      )}
    </>
  );
};

export default FilterIndicatorSeries;
