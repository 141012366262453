import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';
import { getChartColor } from 'src/utils/colors/getChartColor';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { frequencyLatestData } from 'src/utils/charts/getLatestData';

import { ContentLatestData } from './styles';

interface ArimaActualForecastResponse {
  data_tidy: string[];
  y: number[];
  type: string;
}

interface IActualForecast {
  historical: {
    date: string[];
    value: number[];
  };
  forecast: {
    date: string[];
    value: number[];
  };
  seasonally: {
    date: string[];
    value: number[];
  };
}

interface ActualForecastProps {
  modelId: number;
  type:
    | 'arima'
    | 'regularized-regression'
    | 'forecast-combination'
    | 'random-forest'
    | 'elementary';
}

export const ActualForecast: React.FC<ActualForecastProps> = ({
  modelId,
  type,
}) => {
  const { project } = useSelector((state: RootState) => state);

  const [isLatestDataActive, setIsLatestDataActive] = useState<boolean>(true);
  const [isLatestDataDisabled, setIsLatestDataDisabled] = useState(false);

  const { t: translate } = useTranslation();

  const {
    data: actualForecastData,
    isLoading: actualForecastLoading,
    isError: actualForecastError,
  } = useQuery<IActualForecast>(
    [
      `${type} model actualForecast`,
      project.id,
      project.selectedY?.id,
      modelId,
    ],
    async () => {
      const response = await api.get<ArimaActualForecastResponse[]>(
        `/projects/${project.id}/${project.selectedY?.id}/models/${type}/${modelId}/actual_forecast`,
      );

      const historical = response.data.find(
        (data) => data.type === 'Historical' || data.type === 'historical',
      );

      const forecast = response.data.find(
        (data) => data.type === 'Forecast' || data.type === 'forecast',
      );

      const seasonally = response.data.find(
        (data) =>
          data.type === 'Seasonally Adjusted' ||
          data.type === 'seasonally_adjusted',
      );

      return {
        historical: {
          date: historical?.data_tidy || [],
          value: historical?.y || [],
        },
        forecast: {
          date: forecast?.data_tidy || [],
          value: forecast?.y || [],
        },
        seasonally: {
          date: seasonally?.data_tidy || [],
          value: seasonally?.y || [],
        },
      };
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!project.id && !!project.selectedY?.id && !!modelId && !!project.model,
    },
  );

  useEffect(() => {
    if (actualForecastError) {
      setIsLatestDataDisabled(true);
      setIsLatestDataActive(false);
      return;
    }

    if (!actualForecastData || !project.selectedY) {
      return;
    }

    if (!project.selectedY?.info?.frequency) {
      setIsLatestDataDisabled(true);
      setIsLatestDataActive(false);
      return;
    }

    const quantity =
      (actualForecastData?.historical.date.length || 0) +
      (actualForecastData?.forecast.date.length || 0);

    if (quantity <= frequencyLatestData[project.selectedY.info.frequency]) {
      setIsLatestDataDisabled(true);
      setIsLatestDataActive(false);
      return;
    }

    setIsLatestDataDisabled(false);
  }, [actualForecastData, actualForecastError, project]);

  function handleActiveLatestData(value: boolean) {
    setIsLatestDataActive(value);
  }

  const getLatestData = (
    data: IActualForecast | undefined,
    frequency:
      | 'daily'
      | 'weekly'
      | 'fortnightly'
      | 'monthly'
      | 'bimonthly'
      | 'quarterly'
      | 'half-year'
      | 'annual',
  ): IActualForecast => {
    if (data) {
      const quantity = frequencyLatestData[frequency];
      const total = data.historical.date.length + data.forecast.date.length;

      if (quantity > total) {
        return data;
      }

      const forecastLength = quantity / 3 + 1;

      const forecastDate = data.forecast.date.slice(0, forecastLength);
      const forecastValue = data.forecast.value.slice(0, forecastLength);

      const historicalLength = data.historical.date.length;

      const initialIndex =
        historicalLength - quantity + (forecastDate.length - 1);

      const historicalDate = data.historical.date.slice(
        initialIndex,
        historicalLength,
      );

      const historicalValue = data.historical.value.slice(
        initialIndex,
        historicalLength,
      );

      const initialIndexSeasonally = data.seasonally.date.findIndex(
        (date) => date === historicalDate[0],
      );

      const lastIndexSeasonally = data.seasonally.date.findIndex(
        (date) => date === forecastDate.at(-1),
      );

      let seasonallyDate = [];
      let seasonallyValue = [];

      if (initialIndexSeasonally !== -1 && lastIndexSeasonally !== -1) {
        seasonallyDate = data.seasonally.date.slice(
          initialIndexSeasonally,
          lastIndexSeasonally + 1,
        );
        seasonallyValue = data.seasonally.value.slice(
          initialIndexSeasonally,
          lastIndexSeasonally + 1,
        );
      } else if (initialIndexSeasonally !== -1) {
        seasonallyDate = data.seasonally.date.slice(
          initialIndexSeasonally,
          initialIndexSeasonally + quantity,
        );
        seasonallyValue = data.seasonally.value.slice(
          initialIndexSeasonally,
          initialIndexSeasonally + quantity,
        );
      } else if (lastIndexSeasonally !== -1) {
        seasonallyDate = data.seasonally.date.slice(
          lastIndexSeasonally - quantity,
          lastIndexSeasonally + 1,
        );
        seasonallyValue = data.seasonally.value.slice(
          lastIndexSeasonally - quantity,
          lastIndexSeasonally + 1,
        );
      } else {
        seasonallyDate = data.seasonally.date.slice(
          data.seasonally.date.length - 1 - quantity,
          data.seasonally.date.length,
        );
        seasonallyValue = data.seasonally.value.slice(
          data.seasonally.date.length - 1 - quantity,
          data.seasonally.date.length,
        );
      }

      return {
        historical: {
          date: historicalDate,
          value: historicalValue,
        },
        forecast: {
          date: forecastDate,
          value: forecastValue,
        },
        seasonally: {
          date: seasonallyDate,
          value: seasonallyValue,
        },
      };
    }

    return {
      historical: {
        date: [],
        value: [],
      },
      forecast: {
        date: [],
        value: [],
      },
      seasonally: {
        date: [],
        value: [],
      },
    };
  };

  const series: HChartsSeries[] = useMemo(() => {
    const actualForecastAdjusted =
      project.selectedY?.info?.frequency && isLatestDataActive
        ? getLatestData(actualForecastData, project.selectedY.info.frequency)
        : actualForecastData;

    const seriesAux: HChartsSeries[] = [];

    if (actualForecastAdjusted?.historical.date.length) {
      seriesAux.push({
        name: translate('Historical'),
        type: 'line',
        marker: {
          symbol: 'circle',
        },
        color: getChartColor(0),
        dashStyle: 'Solid',
        data: actualForecastAdjusted.historical.date.map((date, dateIndex) => ({
          x: new Date(`${date}T00:00`).getTime(),
          y: actualForecastAdjusted.historical.value[dateIndex] ?? null,
          custom: {
            value: formatCompactNotation(
              actualForecastAdjusted.historical.value[dateIndex],
            ),
          },
        })),
      });
    }

    if (actualForecastAdjusted?.forecast.date.length) {
      seriesAux.push({
        name: translate('Forecast'),
        type: 'line',
        marker: {
          symbol: 'circle',
        },
        color: `${getChartColor(0)}80`,
        dashStyle: 'Dash',
        data: actualForecastAdjusted.forecast.date.map((date, dateIndex) => ({
          x: new Date(`${date}T00:00`).getTime(),
          y: actualForecastAdjusted.forecast.value[dateIndex] ?? null,
          custom: {
            value: formatCompactNotation(
              actualForecastAdjusted.forecast.value[dateIndex],
            ),
          },
        })),
      });
    }

    if (actualForecastAdjusted?.seasonally.date.length) {
      seriesAux.push({
        name: translate('Seasonally_Adjusted'),
        type: 'line',
        marker: {
          symbol: 'circle',
        },
        color: getChartColor(1),
        dashStyle: 'Solid',
        data: actualForecastAdjusted.seasonally.date.map((date, dateIndex) => ({
          x: new Date(`${date}T00:00`).getTime(),
          y: actualForecastAdjusted.seasonally.value[dateIndex] ?? null,
          custom: {
            value: formatCompactNotation(
              actualForecastAdjusted.seasonally.value[dateIndex],
            ),
          },
        })),
      });
    }
    return seriesAux;
  }, [actualForecastData, isLatestDataActive, project, translate]);

  const options: HChartsOptions = useMemo(
    () => ({
      chart: {
        height: 300,
      },
      tooltip: {
        pointFormat:
          `<tr><td><b>${translate('date')}:</b> </td>` +
          `<td style="text-align: right">{point.x: ${
            project.selectedY?.info?.frequency === 'annual' ? '%Y' : ' %d/%m/%Y'
          }}</td></tr>` +
          `<tr><td><b>${translate('value')}:</b> </td>` +
          '<td style="text-align: right">{point.custom.value}</td></tr>',
      },
    }),
    [project.selectedY?.info?.frequency, translate],
  );

  const error = project.projectError || actualForecastError;

  const isLoadingChart = !actualForecastData || actualForecastLoading;

  return (
    <div className="containerLinear">
      <Card textCard={translate('modelSpecActualForecastTitle')} />
      <ContentLatestData>
        <ToggleSwitch
          label={translate('latestData')}
          checked={isLatestDataActive}
          onChange={(e) => handleActiveLatestData(e.target.checked)}
          disabled={isLatestDataDisabled || !!error || !!isLoadingChart}
          data-cy="explanatory-variables-latest-data"
        />
      </ContentLatestData>
      {error ? (
        <ContainerMaintenance content="chart" />
      ) : isLoadingChart ? (
        <ContainerSkeleton />
      ) : (
        <HCharts
          series={series}
          options={options}
          dataCy="chart-actual-forecast"
          frequency={project.selectedY?.info?.frequency}
          resizeWidthWithSidebar
        />
      )}
    </div>
  );
};
