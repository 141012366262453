import { format } from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale';
import { WorkspaceFrequency } from 'src/models/redux/reducers/Workspace';
import { WorkspaceOverviewFrequency } from 'src/models/redux/reducers/WorkspaceOverviewOptions';
import { addThousandSeparator } from 'src/utils/numbers/addThousandSeparator';
import { transformUppercaseFirstLetter } from 'src/utils/strings/transformUppercaseFirstLetter';

type Language = 'pt-br' | 'en-us';

export const variableTypeFormatter = (value: string): string => {
  if (value === 'marketshare') {
    return 'Market Share (%)';
  }

  if (value === 'sellout') {
    return 'Sell Out';
  }

  if (value === 'marketsize') {
    return 'Market Size';
  }

  return transformUppercaseFirstLetter(value);
};

export const dateFormatter = (
  date: string,
  selectedFrequency: WorkspaceOverviewFrequency,
  workspaceFrequency: WorkspaceFrequency,
  language: Language,
): string => {
  let dateFormat = 'MMM/yy';

  if (selectedFrequency === 'original' && !!workspaceFrequency) {
    if (['annual', 'yearly'].includes(workspaceFrequency)) {
      dateFormat = 'yyyy';
    } else if (
      workspaceFrequency === 'daily' ||
      workspaceFrequency === 'fortnightly' ||
      workspaceFrequency === 'weekly'
    ) {
      dateFormat = language === 'en-us' ? 'MM/dd/yy' : 'dd/MM/yy';
    }
  }

  if (['annual', 'yearly'].includes(selectedFrequency)) {
    dateFormat = 'yyyy';
  }

  return format(new Date(date.replace(':00Z', '')), dateFormat, {
    locale: language === 'en-us' ? enUS : ptBR,
  });
};

export const numberFormatter = (value: number, language: Language): string =>
  value ? addThousandSeparator(value, language) : '-';
