import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  ChartLine,
  CheckCircle,
  DotOutline,
  HourglassMedium,
  MagnifyingGlass,
  PencilSimple,
  TrashSimple,
} from '@phosphor-icons/react';
import { RootState } from 'src/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { ButtonRounded } from 'src/components/ButtonRounded';
import { queryClient } from 'src/service/queryClient';
import {
  changeIsDiscardingFirstStep,
  changeWorkspaceOverviewStep,
} from 'src/models/redux/reducers/WorkspaceOverviewOptions';
import { AxiosError } from 'axios';
import { NoPermissionToEditModal } from 'src/models/pages/Workspace/components/NoPermissionToEdit';
import apiWorkspace from 'src/models/service/apiWorkspace';

import {
  AwaitingIcon,
  BlueLine,
  Container,
  CreatingNewStepContent,
  Line,
  StateButton,
  StateContainer,
  StateIcon,
  StepNameContainer,
} from './styles';
import { State, Status, StepProps } from './types';
import { ActionConfirmationModal } from '../../Modals/ActionConfirmation';
import { EditionModeEnabledModal } from '../../Modals/EditionModeEnabled';

export const Step: React.FC<StepProps> = ({
  id,
  name,
  isFirstStep = false,
  currentState,
  isLastStep,
  isSelectedStep,
  canHaveNextStep,
  nextStepState,
  isCreatingStep,
  isLoadingNewStep,
  isRemovingStep,
  messages,
}) => {
  const [showDeleteStepConfirmation, setShowDeleteStepConfirmation] =
    useState(false);
  const [showEditionModeEnabledModal, setShowEditionModeEnabledModal] =
    useState(false);

  const [showUserIsEditingModal, setShowUserIsEditingModal] = useState('');
  const [showWorkspaceIsPublishing, setShowWorkspaceIsPublishing] =
    useState(false);

  const {
    workspace: { id: workspaceId, releaseSelected, userRole },
    workspaceOverviewOptions: { step, editionModeEnabled, reviewModeEnabled },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const { t: translate } = useTranslation();

  const allStates = ['baseline', 'adjusting', 'awaiting_approval', 'approved'];

  const showBlueLine = (state: State) => {
    const stateIndex = allStates.findIndex((value) => value === state);
    const currentStateIndex = allStates.findIndex(
      (value) => value === currentState,
    );

    if (stateIndex <= currentStateIndex) {
      return true;
    }

    return false;
  };

  const getStateStatus = (state: State): Status => {
    if (currentState === 'adjusting' && currentState === state) {
      return 'in progress';
    }

    const stateIndex = allStates.findIndex((value) => value === state);
    const currentStateIndex = allStates.findIndex(
      (value) => value === currentState,
    );

    if (stateIndex > currentStateIndex) {
      return 'to do';
    }

    return 'done';
  };

  const lockEdition = async () => {
    try {
      await apiWorkspace.patch(`/workspaces/${workspaceId}/edit`);

      return true;
    } catch (err) {
      const error = err as AxiosError;
      if (error.response?.status === 400) {
        if (
          error.response?.data?.detail?.detail?.startsWith(
            'Workspace already locked for editing by ',
          )
        ) {
          setShowUserIsEditingModal(
            error.response.data.detail.detail.replace(
              'Workspace already locked for editing by ',
              '',
            ),
          );
        } else if (
          error.response?.data?.detail?.detail ===
          'You cannot perform this action, Workspace is publishing a new version.'
        ) {
          setShowWorkspaceIsPublishing(true);
        }
      }

      return false;
    }
  };

  const unlockEdition = async () => {
    try {
      await apiWorkspace.delete(`/workspaces/${workspaceId}/edit`);
      // eslint-disable-next-line no-empty
    } catch {}
  };

  const handleAccessStep = async (state: State) => {
    queryClient.refetchQueries([
      'workspace',
      workspaceId,
      'releases',
      releaseSelected?.id,
      'logs',
      Number(id),
    ]);

    if (editionModeEnabled) {
      setShowEditionModeEnabledModal(true);
    } else if (step?.number !== Number(id) || step?.selectedStatus !== state) {
      dispatch(
        changeWorkspaceOverviewStep({
          number: Number(id),
          name,
          status: currentState,
          approval_messages: messages?.approval_messages ?? [],
          awaiting_approval_messages:
            messages?.awaiting_approval_messages ?? [],
          disapproval_messages: messages?.disapproval_messages ?? [],
          selectedStatus: state,
        }),
      );
    }
  };

  const handleDeleteStep = async () => {
    const success = await lockEdition();

    if (success) {
      try {
        await apiWorkspace.delete(
          `workspaces/${workspaceId}/releases/${releaseSelected?.id}/steps`,
        );

        await unlockEdition();

        if (isFirstStep) {
          dispatch(changeIsDiscardingFirstStep(true));
        }

        await queryClient.refetchQueries({
          queryKey: ['workspace', workspaceId, 'releases', releaseSelected?.id],
          exact: true,
        });

        queryClient.removeQueries([
          'workspace',
          workspaceId,
          'releases',
          releaseSelected?.id,
          'logs',
          id,
        ]);

        await queryClient.refetchQueries({
          queryKey: ['workspace', 'logs'],
        });

        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => {
            const queryKeyString = queryKey.slice(0, 7).toString();

            if (
              queryKeyString.startsWith(
                `workspace,series data,${workspaceId},${releaseSelected?.id}`,
              ) &&
              queryKeyString.endsWith(id)
            ) {
              return true;
            }

            return false;
          },
        });

        // eslint-disable-next-line no-empty
      } catch {}
    }
  };

  const isManager = userRole === 'manager';

  const showLastLine = !!nextStepState || (canHaveNextStep && isManager);
  const showLastLineBlue = !isLastStep;

  const showDeleteStep =
    isLastStep &&
    isManager &&
    (!isFirstStep || currentState !== 'baseline') &&
    !isLoadingNewStep;

  if (isCreatingStep) {
    return (
      <Container
        hasPaddingRight
        hasPaddingLeft
        data-testid={`container-creating-step-${id}`}
      >
        <StepNameContainer>
          <p data-testid="text-step-name">{name}</p>
        </StepNameContainer>

        <CreatingNewStepContent>
          <ContainerSkeleton withLoading />

          <p>{translate('workspaceOverviewPlanningFlowCreatingStep')}</p>
        </CreatingNewStepContent>
      </Container>
    );
  }

  return (
    <Container
      hasPaddingRight={!nextStepState}
      hasPaddingLeft={isFirstStep}
      data-testid={`container-step-${id}`}
    >
      <StepNameContainer>
        <p data-testid="text-step-name">{name}</p>
      </StepNameContainer>

      <Line style={{ width: '3.5rem' }}>
        <BlueLine show={isFirstStep || currentState !== 'baseline'} />
      </Line>

      {isFirstStep && (
        <>
          <StateContainer
            status="done"
            selected={
              currentState === 'baseline' ||
              (isSelectedStep && step?.selectedStatus === 'baseline')
            }
            data-testid="container-preview-state"
          >
            <StateIcon data-testid="icon-preview-state">
              <ChartLine weight="fill" />
            </StateIcon>

            <StateButton
              onClick={() => handleAccessStep('baseline')}
              data-testid="button-preview-state"
            >
              <MagnifyingGlass />
            </StateButton>

            <p data-testid="text-preview-state">
              {translate('workspaceOverviewPlanningFlowPreview')}
            </p>
          </StateContainer>

          <Line>
            <BlueLine show={showBlueLine('adjusting')} />
          </Line>
        </>
      )}

      <StateContainer
        status={getStateStatus('adjusting')}
        selected={
          currentState === 'adjusting' &&
          isSelectedStep &&
          step?.selectedStatus === 'adjusting'
        }
        data-testid="container-adjusting-state"
      >
        <StateIcon data-testid="icon-adjusting-state">
          <PencilSimple weight="fill" />
        </StateIcon>

        {currentState === 'adjusting' &&
        step?.selectedStatus !== 'adjusting' ? (
          <StateButton
            onClick={() => handleAccessStep('adjusting')}
            data-testid="button-adjusting-state"
          >
            <MagnifyingGlass />
          </StateButton>
        ) : (
          <DotOutline weight="fill" style={{ width: '2rem', height: '2rem' }} />
        )}

        <p data-testid="text-adjusting-state">
          {translate('workspaceOverviewPlanningFlowAdjusting')}
        </p>
      </StateContainer>

      {currentState === 'awaiting_approval' ? (
        <>
          <Line style={{ width: '2.125rem', marginRight: '0.5rem' }}>
            <BlueLine show />
          </Line>

          <AwaitingIcon
            type="button"
            onClick={() => handleAccessStep('awaiting_approval')}
            data-testid="button-waiting-approval"
          >
            {currentState === 'awaiting_approval' &&
            step?.selectedStatus !== 'awaiting_approval' ? (
              <MagnifyingGlass data-testid="magnifying-glass-waiting-approval-icon" />
            ) : (
              <HourglassMedium data-testid="hourglass-waiting-approval-icon" />
            )}
          </AwaitingIcon>

          <Line style={{ width: '2.125rem', marginLeft: '0.5rem' }}>
            <BlueLine show={false} />
          </Line>
        </>
      ) : (
        <Line>
          <BlueLine show={showBlueLine('approved')} />
        </Line>
      )}

      <StateContainer
        status={getStateStatus('approved')}
        selected={
          currentState === 'approved' &&
          isSelectedStep &&
          step?.selectedStatus === 'approved'
        }
        isGreenBackground={
          currentState === 'approved' &&
          isSelectedStep &&
          step?.selectedStatus === 'approved'
        }
        data-testid="container-approved-state"
        style={{ marginRight: showLastLine ? '0' : '1.25rem' }}
      >
        <StateIcon data-testid="icon-approved-state">
          <CheckCircle weight="fill" />
        </StateIcon>

        {currentState === 'approved' ? (
          <StateButton
            onClick={() => handleAccessStep('approved')}
            data-testid="button-approved-state"
          >
            <MagnifyingGlass />
          </StateButton>
        ) : (
          <DotOutline weight="fill" style={{ width: '2rem', height: '2rem' }} />
        )}

        <p data-testid="text-approved-state">
          {translate('workspaceOverviewPlanningFlowApproved')}
        </p>
      </StateContainer>

      {showLastLine && (
        <Line style={{ width: '3.5rem' }} data-testid="last-line">
          <BlueLine show={showLastLineBlue} />
        </Line>
      )}

      {showEditionModeEnabledModal && (
        <EditionModeEnabledModal setVisible={setShowEditionModeEnabledModal} />
      )}

      {showDeleteStep && (
        <ButtonRounded
          onClick={() => setShowDeleteStepConfirmation(true)}
          icon={<TrashSimple />}
          disabled={isRemovingStep || editionModeEnabled || reviewModeEnabled}
          data-testid="button-delete-step"
          data-tooltip-id="planning-flow"
          data-tooltip-content={
            isRemovingStep
              ? translate('workspaceOverviewPlanningFlowIsRemoving')
              : editionModeEnabled
              ? translate('workspaceOverviewResultsEditionBlockRemoveStage')
              : reviewModeEnabled
              ? translate('workspaceOverviewResultsReviewBlockRemoveStage')
              : undefined
          }
        />
      )}

      {showDeleteStepConfirmation && (
        <ActionConfirmationModal
          title={translate('workspaceOverviewDeleteStepConfirmationModalTitle')}
          description={translate(
            'workspaceOverviewDeleteStepConfirmationModalDescription',
          )}
          textToConfirm={name}
          onConfirm={handleDeleteStep}
          setVisible={setShowDeleteStepConfirmation}
        />
      )}

      {(!!showUserIsEditingModal || showWorkspaceIsPublishing) && (
        <NoPermissionToEditModal
          setVisible={() => {
            setShowUserIsEditingModal('');
            setShowWorkspaceIsPublishing(false);
          }}
          emailEditing={showUserIsEditingModal}
          errorDescription={
            editionModeEnabled
              ? translate(
                  'workspaceOverviewResultsEditionLockedDescription',
                ).replace('XXX', showUserIsEditingModal)
              : showWorkspaceIsPublishing
              ? translate('workspaceOverviewResultsWorkspaceIsPublishing')
              : undefined
          }
        />
      )}
    </Container>
  );
};
